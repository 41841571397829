<div class="title-area" hidden="true">
</div>
<div class="table-area">
  <div class="row">
    <div class="col-sm-2">
      <select [disabled] = "!authService.isSystemAdmin" name="storeCountry" class="form-control" [(ngModel)]="storeCountry" 
      (focusout)="clearErrorIfValidRequire($event.target)" required="required" (change)="onSelectCountry($event)">
        <option class="default-option" value="">{{countryDefaultSelectOption  ? ('SELECT_COUNTRY' | translate) : countryDefaultSelectOption}}</option>
        <option *ngFor="let country of countries" [value]="country">{{country}}</option>
      </select>
      <span class="underline"></span>
      <div class="text-error" for="storeCountry"></div>
    </div>
    <div class="col-sm-2">
      <select name="storeCode" [disabled] = "(authService.isSM || authService.isSMT) || storeCountry===''" class="form-control" [(ngModel)]="storeCode" 
      (focusout)="clearErrorIfValidRequire($event.target)" required="required" (change)="onSelectStore($event)">
        <option class="default-option" value="">{{storeDefaultSelectOption ? ('SELECT_STORE' | translate) : storeDefaultSelectOption}}</option>
        <option *ngFor="let store of stores" [value]="store.storeCode">{{store.storeName}}</option>
      </select>
      <span class="underline"></span>
      <div class="text-error" for="storeCode"></div>
    </div>
    <div class="col-sm-2">
      <select name="activityType" [disabled] = "!storeCountry || !storeCode" class="form-control" [(ngModel)]="activityType" (change)="onSelectActivityType($event)">
        <option class="default-option" value="">{{activityTypeSelectPlaceHolder ? ('SELECT_ACTIVITY_TYPE' | translate) : activityTypeSelectPlaceHolder}}</option>
        <option *ngFor="let at of activityTypeList" [value]="at.id">{{at.activityTypeName}}</option>
      </select>
    </div>
    <div class="col-sm-4" style="display:inline-block">
      <div style="position:relative; width:100%">
        <div (click)="getBookingList(1)" class="btn-search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
        <input [disabled] = "!storeCode" style="width:100%" class="form-control pull-right search-bar" type="search" placeholder="{{search ? ('SEARCH_CLIENT' | translate) : search}}"
          aria-label="Search" [(ngModel)]="keywords" (keyup.enter)="getBookingList(1)" (input)="onSearchInput()">
        <div class="btn-clear" *ngIf="onSearch" (click)="clearSearch()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div class="col-sm-2 btn-div">
      <button type="button" [disabled] = "!bookings.length || authService.isBM || authService.isBR" class="btn btn-danger btn-new" (click)="download()">{{downloadLbl ? ('DOWNLOAD' | translate) : downloadLbl}}</button>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-2">
      <label class="col-form-label">{{from ? ('FROM_LABEL' | translate) : from}}</label>
      <input type="date" name="startDate" class="form-control" [disabled]="!storeCode" [(ngModel)]="startDate" (change)="onChangeStartDate()"/>
      <span class="underline"></span>
      <div class="text-error" for="startDate"></div>
    </div>
    <div class="col-sm-2">
      <label class="col-form-label">{{to ? ('TO_LABEL' | translate) : to}}</label>
      <input type="date" name="endDate" class="form-control" [disabled]="!storeCode || !startDate" [(ngModel)]="endDate" (change)="onChangeEndDate()"/>
      <span class="underline"></span>
      <div class="text-error" for="endDate"></div>
    </div>
    <div class="col-sm-2">
      <label class="col-form-label">{{resourceFilterLbl ? ('FILTER_BY_RESOURCE' | translate) : resourceFilterLbl}}</label>
      <ng-multiselect-dropdown class="form-control-sm" id="resourceMultiSelect" [disabled]="!storeCode"
      [placeholder]="resourceMultiselectPlaceHolder ? ('SELECT_OPTION' | translate) : resourceMultiselectPlaceHolder"
      [settings]="resourceDropdownSettings"
      [data]="resourceDropdownList"
      [(ngModel)]="selectedResources"
      (onSelect)="onResourceSelect($event)"
      (onDeSelect)="onResourceDeSelect()"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-2">
      <label class="col-form-label">{{activityFilterLbl ? ('FILTER_BY_ACTIVITY' | translate) : activityFilterLbl}}</label>
      <ng-multiselect-dropdown class="form-control-sm" id="activityMultiSelect" [disabled]="!storeCode"
      [placeholder]="activityMultiselectPlaceHolder ? ('SELECT_OPTIONS' | translate) : activityMultiselectPlaceHolder"
      [settings]="activityDropdownSettings"
      [data]="activityDropdownList"
      [(ngModel)]="selectedActivities"
      (onSelect)="onActivitySelect($event)"
      (onDeSelect)="onActivityDeSelect($event)"
      (onSelectAll)="onActivitySelectAll($event)"
      (onDeSelectAll)="onActivityDeSelectAll()"
      >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-sm-2">
      <label class="col-form-label">{{statusFilterLbl ? ('FILTER_BY_STATUS' | translate) : statusFilterLbl}}</label>
      <ng-multiselect-dropdown class="form-control-sm" id="statusMultiSelect" [disabled]="!storeCode"
      [placeholder]="statusMultiselectPlaceHolder ? ('SELECT_OPTIONS' | translate) : statusMultiselectPlaceHolder"
      [settings]="statusDropdownSettings"
      [data]="statusDropdownList"
      [(ngModel)]="selectedStatuses"
      (onSelect)="onStatusSelect($event)"
      (onDeSelect)="onStatusDeSelect($event)"
      (onSelectAll)="onStatusSelectAll($event)"
      (onDeSelectAll)="onStatusDeSelectAll()"
      >
      </ng-multiselect-dropdown>
    </div>
  </div>

  <div *ngIf="isLoading" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg">
  </div>
  <div id="BookingRes" *ngIf="bookings?.length || (!bookings?.length && keywords)">
    <div class="table-details" *ngIf="!bookings?.length"></div>
    <div class="table-details" *ngIf="bookings?.length">{{showingBookingsFrom ? ('SHOWING_BOOKINGS_FROM' | translate) : showingBookingsFrom}} {{beginRecord}} {{to ? ('to' | translate) : to}} {{endRecord}} {{ofTotal ? ('OF_TOTAL' | translate) : ofTotal}} {{this.totalRecords}} {{bookingsMsg ? ('BOOKINGS_MSG' | translate) : bookingsMsg}}</div>
    <div class="iPadTable">
      <table class="table table-hover table-ba" [class.opaque]="isLoading">
        <thead class="thead-dark">
          <tr>
            <th class="col-md-1">{{dateLabel ? ('DATE_LABEL' | translate) : dateLabel}}</th>
            <th class="col-md-1">{{timeslotLabel ? ('TIME_SLOT_LABEL' | translate) : timeslotLabel}}</th>
            <th class="col-md-1">{{resourceLabel ? ('RESOURCE_LABEL' | translate) : resourceLabel}}</th>
            <th class="col-md-1">{{ticketNoLabel ? ('TICKET_NO_LABEL' | translate) : ticketNoLabel}}</th>
            <th class="col-md-1">{{clientNameLabel ? ('CLIENT_NAME_LABEL' | translate) : clientNameLabel}}</th>
            <th class="col-md-1">{{clientContactLabel ? ('CLIENT_CONTACT_LABEL' | translate) : clientContactLabel}}</th>
            <th class="col-md-1">{{activityTypeLabel ? ('ACTIVITY_TYPE_LABEL' | translate) : activityTypeLabel}}</th>
            <th class="col-md-2">{{activityLabel ? ('ACTIVITY_LABEL' | translate) : activityLabel}}</th>
            <!--<th class="col-md-1">{{baAttended ? ('BA_ATTENDED' | translate) : baAttended}}</th>
            <th class="col-md-1">{{baName ? ('BA_NAME' | translate) : baName}}</th>-->
            <th class="col-md-1">{{statusLabel ? ('STATUS_LABEL' | translate) : statusLabel}}</th>
            <th class="col-md-1 text-align-center" colspan="3">{{action ? ('ACTION' | translate) : action}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let booking of bookings | paginate: { itemsPerPage: limit, currentPage: currentPage , id: 'item-server', totalItems: totalRecords }; let i = index">
            <td>{{booking.dateSlot}}</td>
            <td>{{booking.timeSlot}}</td>
            <td>{{booking.resourceName}}</td>
            <td>{{booking.confirmationNumber}}</td>
            <td>{{booking.clientName}}</td>
            <td>{{booking.clientContact}}</td>
            <td>{{booking.activityType}}</td>
            <td>{{booking.activityName}}</td>
            <!--<td>{{booking.baAttended}}</td>
            <td>{{booking.baName}}</td>-->
            <td>{{booking.bamStatusText}}</td>
            <td *ngIf="!booking.cancelled && !booking.completed" align="right">
              <div *ngIf="booking.startable">
                <button type="button" class="btn btn-danger" (click)="updateStatus(booking.confirmationNumber, 'in-progress', 'IN_PROGRESS')">{{startBtn ? ('START_BTN' | translate) : startBtn}}</button>
              </div>
              <div *ngIf="booking.checkinable">
                <button type="button" class="btn btn-danger" (click)="updateStatus(booking.confirmationNumber, 'checked-in', 'CHECKED_IN')">{{checkInBtn ? ('CHECK_IN_BTN' | translate) : checkInBtn}}</button>
              </div>
              <div *ngIf="booking.completable">
                <button type="button" class="btn btn-danger" (click)="updateStatus(booking.confirmationNumber, 'completed', 'COMPLETED')">{{completeBtn ? ('COMPLETE_BTN' | translate) : completeBtn}}</button>
              </div>
            </td>
            <td *ngIf="!booking.cancelled && !booking.completed" align="right">
              <div *ngIf="booking.revertable">
                <button type="button" class="btn btn-outline-dark" (click)="updateStatus(booking.confirmationNumber, 'booked', 'BOOKED')">{{revertBtn ? ('REVERT_BTN' | translate) : revertBtn}}</button>
              </div>
              <div *ngIf="booking.noshowable">
                <button type="button" class="btn btn-outline-dark" (click)="updateStatus(booking.confirmationNumber, 'no-show-manual-update', 'NO_SHOW_MANUAL_UPDATE')">{{noShowBtn ? ('NO_SHOW_BTN' | translate) : noShowBtn}}</button>
              </div>
            </td>
            <td *ngIf="!booking.cancelled && !booking.completed" align="right">
              <div *ngIf="booking.cancelable">
                <button type="button" class="btn btn-outline-danger" (click)="updateStatus(booking.confirmationNumber, 'sephora-cancelled', 'SEPHORA_CANCELLED')">{{(cancelBtn  ? ('CANCEL' | translate) : cancelBtn)}}</button>
              </div>
            </td>
            <td *ngIf="booking.completed" colspan="3">{{booking.lastModifiedText}}</td>
            <td *ngIf="booking.cancelled" colspan="3">{{booking.lastModifiedText}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="text-align-center" *ngIf="!isLoading && !bookings?.length">{{noBookingFound ? ('NO_BOOKING_FOUND' | translate) : noBookingFound}}</div>
    <div class="pagination" *ngIf="!isLoading && bookings?.length">
      <pagination-controls (pageChange)="getBookingList($event)" id="item-server"></pagination-controls>
    </div>
  </div>
</div>