import {Component, OnInit} from '@angular/core';
import Bugsnag from '@bugsnag/js';
import {TranslateService} from '@ngx-translate/core';

import {AuthService} from 'src/app/services/auth.service';
import {StoreService} from 'src/app/services/store.service';
import {ResourceService} from 'src/app/services/resource.service';
import {NotificationsService} from 'angular2-notifications';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

import {CONSTANTS} from 'src/app/common/constants';
import {Store} from 'src/app/models/store';

// Jquery
declare var $: any;

@Component({
  selector: 'resource-management-component',
  templateUrl: './resource-management.component.html',
  styleUrls: ['./resource-management.component.css']
})

export class ResourceManagementComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    private storeService: StoreService,
    private resourceService: ResourceService,
    public translate: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  newResource: string = CONSTANTS.NEW_RESOURCE;
  countryDefaultSelectOption: string = CONSTANTS.SELECT_COUNTRY;
  storeCountry: string = '';
  countries: string[] = [];
  storeDefaultSelectOption: string = CONSTANTS.SELECT_STORE;
  storeCode: string = '';
  stores: Store[] = [];
  resources: any[] = [];

  resourceNameLabel: string = CONSTANTS.RESOURCE_NAME_LABEL;
  expertiseLabel: string = CONSTANTS.EXPERTISE_LABEL;
  activityGroupLabel: string = CONSTANTS.ACTIVITY_GROUP_LABEL;
  activityNameLabel: string = CONSTANTS.ACTITIVY_NAME_LABEL;
  scheduleLabel: string = CONSTANTS.SCHEDULE_LABEL;
  showingResourcesFrom: string = CONSTANTS.SHOWING_RESOURCES_FROM;
  ofTotal: string = CONSTANTS.OF_TOTAL;
  to: string = CONSTANTS.TO;
  resourcesMsg: string = CONSTANTS.RESOURCES_MSG;
  noResourceFound: string = CONSTANTS.NO_RESOURCE_FOUND;
  search: string = CONSTANTS.SEARCH_RESOURCE;
  deactivate: string = CONSTANTS.DEACTIVATE;
  activate: string = CONSTANTS.ACTIVATE;
  expertiseMultiselectPlaceHolder: string = CONSTANTS.EXPERTISE_FILTER;
  activityGroupMultiselectPlaceHolder: string = CONSTANTS.ACTIVITY_GROUP_FILTER;
  activityNameMultiselectPlaceHolder: string = CONSTANTS.ACTIVITY_NAME_FILTER;
  // multiselectAll: string = CONSTANTS.SELECT_ALL;
  // multisunelectAll: string = CONSTANTS.UNSELECT_ALL;

  currentPage: number = 1;
  beginRecord: number = 0;
  endRecord: number = 0;
  totalRecords: number = 0;
  limit: number = 50;

  expertiseIds: any[] = [];
  activityGroupIds: any[] = [];
  activityNameIds: any[] = [];

  noActiveResourcesLabel: string = CONSTANTS.NO_ACTVCE_RESOURCES_LABEL;
  noActiveResourceActivityNames: string = '';

  multiselectAll: string = '';
  multisunelectAll: string = '';

  // expertiseMultiselectPlaceHolder: string = '';
  expertiseDropdownList: any[] = [];
  expertiseDropdownSettings: IDropdownSettings = {};
  selectedExpertise: any[] = [];

  // ActivityGroupMultiselectPlaceHolder: string = '';
  activityGroupDropdownList: any[] = [];
  activityGroupDropdownSettings: IDropdownSettings = {};
  selectedActivityGroups: any[] = [];
  allActivityGroups: any[] = [];

  ActivityNameMultiselectPlaceHolder: string = '';
  activityNameDropdownList: any[] = [];
  activityNameDropdownSettings: IDropdownSettings = {};
  selectedActivityNames: any[] = [];
  allActivityNames: any[] = [];

  oneOffLabel: string = CONSTANTS.ONE_OFF_LABEL;
  recurringLabel: string = CONSTANTS.RECURRING_LABEL;

  action: string = CONSTANTS.ACTION;
  keywords = '';
  onSearch: boolean = false;

  isLoading: boolean = false;

  view: string = CONSTANTS.VIEW;
  edit: string = CONSTANTS.EDIT;

  ngOnInit(): void {
    if ((localStorage.getItem('goingBack') as string) === 'true') {
      localStorage.removeItem('goingBack');
      this.storeCountry = localStorage.getItem('resourceStoreCountry') as string;
      localStorage.removeItem('resourceStoreCountry');
      this.storeCode = localStorage.getItem('resourceStoreCode') as string;
      localStorage.removeItem('resourceStoreCode');
      this.keywords = localStorage.getItem('resourceKeywords') as string;
      localStorage.removeItem('resourceKeywords');
      this.expertiseIds = (localStorage.getItem('expertiseIds') as string) ? (localStorage.getItem('expertiseIds') as string).split(',').map(Number) : [];
      localStorage.removeItem('expertiseIds');
      this.activityGroupIds = JSON.parse(localStorage.getItem('activityGroupIds') as string);
      localStorage.removeItem('activityGroupIds');
      this.activityNameIds = JSON.parse(localStorage.getItem('activityNameIds') as string);
      localStorage.removeItem('activityNameIds');
      this.currentPage = Number(localStorage.getItem('resourcePage') as string);
      localStorage.removeItem('resourcePage');
      this.limit = Number(localStorage.getItem('resourceLimit') as string);
      localStorage.removeItem('resourceLimit');
    } else {
      this.storeCountry = (this.authService.isSystemAdmin || this.authService.isRetailOps) ? this.authService.location : this.authService.storeCountry;
      this.storeCode = (this.authService.isSystemAdmin || this.authService.isRetailOps || this.authService.isBM) ? '' : this.authService.storeCode;
    }

    this.countries = this.authService.isSystemAdmin ? this.authService.countries : [this.storeCountry];
    this.storeService.getStoresByCountry(this.storeCountry).subscribe(res => {
      this.stores = res;
      if (this.storeCode && this.stores.filter((it: any) => [this.storeCode].includes(it.storeCode as string)).length > 0) {
        this.fetchAllActivityGroups(this.storeCode);
        this.fetchActivityGroups(this.storeCode);
      }
    });

    this.oneOffLabel = this.translate.instant(CONSTANTS.ONE_OFF_LABEL);
    this.recurringLabel = this.translate.instant(CONSTANTS.RECURRING_LABEL);

    // this.expertiseMultiselectPlaceHolder = this.translate.instant(CONSTANTS.EXPERTISE_FILTER);
    // this.ActivityGroupMultiselectPlaceHolder = this.translate.instant(CONSTANTS.ACTIVITY_GROUP_FILTER);
    // this.ActivityNameMultiselectPlaceHolder = this.translate.instant(CONSTANTS.ACTIVITY_NAME_FILTER);
    this.multiselectAll = this.translate.instant(CONSTANTS.SELECT_ALL);
    this.multisunelectAll = this.translate.instant(CONSTANTS.UNSELECT_ALL);

    this.fetchExpertise();

    this.expertiseDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'expertise',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.activityGroupDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'activityGroup',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.activityNameDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'activityName',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.getResourceList(this.currentPage);
  }

  private fetchExpertise() {
    this.isLoading = true;
    this.resourceService.fetchExpertise().subscribe(
      res => {
        this.expertiseDropdownList = res.body.expertise;
        this.isLoading = false;
     },
      err => {
        Bugsnag.notify(err);
        this.isLoading = false;
     }
    );
  }

  private fetchAllActivityGroups(storeCode: string) {
    if (this.storeCode) {
      this.isLoading = true;
      this.resourceService.fetchActivityGroups('', storeCode).subscribe(
        res => {
          this.allActivityGroups = res.body.activityGroups;
          this.resourceService.fetchActivityNames(storeCode, []).subscribe(
            res => {
              this.allActivityNames = res.body.activityNames;
              this.isLoading = false;
              this.getResourceList(1);
           },
            err => {
              Bugsnag.notify(err);
              this.isLoading = false;
           }
          );
       },
        err => {
          Bugsnag.notify(err);
          this.isLoading = false;
       }
      );
    }
  }

  private fetchActivityGroups(storeCode: string) {
    this.isLoading = true;
    this.resourceService.fetchActivityGroups('', storeCode).subscribe(
      res => {
        this.storeCode = storeCode;
        if (res.body.activityGroups.length) {
          this.activityGroupDropdownList = res.body.activityGroups;
        } else {
          this.storeCode = '';
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.RESOURCE_FETCH_STORE_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            }
          );
        }
        this.isLoading = false;
      },
      err => {
        Bugsnag.notify(err);
        this.isLoading = false;
      }
    );
  }

  private fetchActivities() {
    if (!this.activityGroupIds || !this.activityGroupIds.length) {
      this.activityNameDropdownList = [];
      this.activityNameIds = [];
      this.selectedActivityNames = [];
    } else {
      this.isLoading = true;
      this.resourceService.fetchActivityNames(this.storeCode, this.activityGroupIds).subscribe(
        res => {
          this.activityNameDropdownList = res.body.activityNames;
          if (this.activityNameIds.length) {
            const allActivityNameIds = this.activityNameDropdownList.map((e: any) => {return e.id});
            this.activityNameIds = this.activityNameIds.filter(i => allActivityNameIds.includes(i));
            this.selectedActivityNames = this.selectedActivityNames.filter(a => this.activityNameIds.includes(a.id));
          }
          this.isLoading = false;
        },
        err => {
          Bugsnag.notify(err);
          this.isLoading = false;
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.RESOURCE_FETCH_STORE_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            }
          );
        }
      );
    }
  }

  onSelectCountry(event: any) {
    const storeCountry = (event.target as HTMLInputElement).value as string;
    this.storeCode = '';
    if (storeCountry && this.countries.includes(storeCountry)) {
      this.storeCountry = storeCountry;
      this.storeService.getStoresByCountry(storeCountry).subscribe(res => {
        this.stores = res;
        this.resources = [];
      });
      this.clearError('storeCountry');
    } else {
      this.resources = [];
      this.showError('storeCountry', CONSTANTS.SELECT_COUNTRY);
    }
  }

  onSelectStore(event: any) {
    this.storeCode = (event.target as HTMLInputElement).value as string;
    if (this.storeCode && this.stores.filter((it: any) => [this.storeCode].includes(it.storeCode as string)).length > 0) {
      this.clearError('storeCode');
      this.fetchActivityGroups(this.storeCode);
      this.fetchAllActivityGroups(this.storeCode);
    } else {
      this.storeCode = '';
      this.resources = [];
      this.activityGroupDropdownList = [];
      this.activityNameDropdownList = [];
      this.showError('storeCode', CONSTANTS.SELECT_STORE);
    }
  }

  getResourceList(page: number) {
    if (this.storeCode) {
      this.isLoading = true;
      this.resourceService.search(page, this.limit, this.storeCode, this.keywords, this.expertiseIds, this.activityGroupIds, this.activityNameIds).subscribe(
        res => {
          this.resources = res.items;
          this.resources.forEach((i: any) => {
            i.expertise = (i.expertise.map((e: any) => {return e.expertise})).toString().replaceAll(',', ', ');
            i.activityGroups = this.activityGroupDropdownList.length ? (this.activityGroupDropdownList.filter((it: any) => (i.activityGroupIds.includes(it.id))).map((e: any) => {return e.activityGroup})).toString().replaceAll(',', ', ') : (this.allActivityGroups.filter((it: any) => (i.activityGroupIds.includes(it.id))).map((e: any) => {return e.activityGroup})).toString().replaceAll(',', ', ');
            i.activityNames = this.activityNameDropdownList.length ? (this.activityNameDropdownList.filter((it: any) => (i.activityNameIds.includes(it.id))).map((e: any) => {return e.activityName})).toString().replaceAll(',', ', ') : (this.allActivityNames.filter((it: any) => (i.activityNameIds.includes(it.id))).map((e: any) => {return e.activityName})).toString().replaceAll(',', ', ');
          });
          this.totalRecords = res.total;
          this.currentPage = page;
          this.beginRecord = (page - 1) * this.limit + 1;
          if (res.total == 0) this.beginRecord = 0;
          this.endRecord = this.beginRecord + this.limit - 1;
          if (this.endRecord > this.totalRecords) this.endRecord = this.totalRecords;
          this.selectedExpertise = this.expertiseDropdownList.filter((it: any) => this.expertiseIds.includes(it.id as number));
          this.selectedActivityGroups = this.activityGroupDropdownList.filter((it: any) => this.activityGroupIds.includes(it.id as number));
          this.selectedActivityNames = this.activityNameDropdownList.filter((it: any) => this.activityNameIds.includes(it.id as number));
          this.noActiveResourceActivityNames = this.activityNameDropdownList.length ?  (this.activityNameDropdownList.filter((it: any) => (!res.awar.includes(it.id))).map((e: any) => {return e.activityName})).toString().replaceAll(',', ', ') : (this.allActivityNames.filter((it: any) => (!res.awar.includes(it.id))).map((e: any) => {return e.activityName})).toString().replaceAll(',', ', ');
          this.isLoading = false;
        },
        err => {
          Bugsnag.notify(err);
          this.isLoading = false;
        }
      );
    }
  }

  onSearchInput() {
    this.onSearch = this.keywords != ''
    this.getResourceList(1);
  }

  clearSearch() {
    this.keywords = '';
    this.onSearch = this.keywords != ''
    this.getResourceList(1);
  }

  beforeGoToAResoucePage() {
    localStorage.setItem('goingBack', 'true');
    this.setGoBackProperties();
  }

  private setGoBackProperties() {
    localStorage.setItem('resourceStoreCode', this.storeCode);
    localStorage.setItem('resourceStoreCountry', this.storeCountry);
    localStorage.setItem('resourceKeywords', this.keywords);
    localStorage.setItem('expertiseIds', this.expertiseIds.toString());
    localStorage.setItem('activityGroupIds', JSON.stringify(this.activityGroupIds));
    localStorage.setItem('activityNameIds', JSON.stringify(this.activityNameIds));
    localStorage.setItem('resourcePage', this.currentPage + '');
    localStorage.setItem('resourceLimit', this.limit + '');
  }

  clearErrorIfValidRequire(target: any) {
    let content = target.value;
    if (content && content.trim()) {
      this.clearError(target.name);
    }
  }

  private clearError(inputId: string) {
    $("div.text-error[for='" + inputId + "']").hide();
  }

  private showError(inputId: string, message: string) {
    $("div.text-error[for='" + inputId + "']").html(message).show();
  }

  deactivateResource(id: number, status: number) {
    this.resourceService.updateStatus(this.storeCode, id, status ? 0 : 1).subscribe(
      res => {
        this.resources.forEach((r: any) => {
          if (r.id === id) {
            r.status = r.status ? 0 : 1;
          }
        });
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.getResourceList(1);
        this.isLoading = false;
      },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.RESOURCE_UPDATE_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        Bugsnag.notify(err);
      }
    );
  }

  onExpertiseSelect(item: any) {
    this.expertiseIds.push(item.id);
    this.getResourceList(1);
  }

  onExpertiseDeSelect(item: any) {
    this.expertiseIds = this.expertiseIds.filter(i => i != item.id);
    this.getResourceList(1);
  }

  onExpertiseSelectAll(items: any) {
    this.expertiseIds = this.expertiseDropdownList.map((e: any) => {return e.id});
    this.getResourceList(1);
  }

  onExpertiseDeselectAll(items: any) {
    this.expertiseIds = [];
    this.getResourceList(1);
  }

  onActivityGroupSelect(item: any) {
    this.activityGroupIds.push(item.id);
    this.fetchActivities();
    this.getResourceList(1);
  }

  onActivityGroupDeSelect(item: any) {
    this.activityGroupIds = this.activityGroupIds.filter(i => i != item.id);
    this.fetchActivities();
    this.getResourceList(1);
  }

  onActivityGroupSelectAll(items: any) {
    this.activityGroupIds = this.activityGroupDropdownList.map((e: any) => {return e.id});
    this.fetchActivities();
    this.getResourceList(1);
  }

  onActivityGroupDeselectAll(items: any) {
    this.activityGroupIds = [];
    this.selectedActivityNames = [];
    this.activityNameIds = [];
    this.fetchActivities();
    this.getResourceList(1);
  }

  onActivityNameSelect(item: any) {
    this.activityNameIds.push(item.id);
    this.getResourceList(1);
  }

  onActivityNameDeSelect(item: any) {
    this.activityNameIds = this.activityNameIds.filter(i => i != item.id);
    this.getResourceList(1);
  }

  onActivityNameSelectAll(items: any) {
    this.activityNameIds = this.activityNameDropdownList.map((e: any) => {return e.id});
    this.getResourceList(1);
  }

  onActivityNameDeselectAll(items: any) {
    this.activityNameIds = [];
    this.getResourceList(1);
  }
}