import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as moment from 'moment';
import 'moment-timezone';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin } from 'rxjs';

import { FullCalendarComponent } from '@fullcalendar/angular';
import { BusinessHoursInput, CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CODINGCONSTANTS } from 'src/app/common/coding-constants';
import { CONSTANTS } from 'src/app/common/constants';
import { ICONS } from 'src/app/common/icons';
import { MessageModalComponent } from 'src/app/components/modal/message-modal/message-modal.component';
import { ResourceForm } from 'src/app/models/resource-form';
import { Store } from 'src/app/models/store';
import { AuthService } from 'src/app/services/auth.service';
import { ResourceService } from 'src/app/services/resource.service';
import { StoreService } from 'src/app/services/store.service';
declare var $: any;


@Component({
  selector: 'resource-component',
  // template: `
  //  <full-calendar 
  //     [options]="calendarOptions">
  //   </full-calendar>
  // `,
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit, AfterViewInit {
  @ViewChild(MessageModalComponent) messageModal!: MessageModalComponent;
  @ViewChild('calendar', { static: false }) calendarComponent!: FullCalendarComponent;
  calendarOptions!: CalendarOptions;

  clickedEvents: any[] = [];
  calendarEvents: EventInput[] = [];
  existingSlots: any[] = []; // i need to pre populate time slot/event for a resource.
  selectedSlots: any[] = []; // Initialize an array to store selected slots when a new slot/event is getting selected
  removedSlots: any[] = []; // need to remove the event or slots that os not required and need to remove those from the selectedSlots, existingSlots and updatedslots
  updatedslots: any[] = []; // need to add elements in this array when existingSlots is changed
  selectedSlot: any = {  // Initialize selected slot with default values
    start: '2024-06-10T09:00:00',
    end: '2024-06-10T10:30:00'
  };
  


  countryDefaultSelectOption: string = CONSTANTS.SELECT_COUNTRY;
  storeDefaultSelectOption: string = CONSTANTS.SELECT_STORE;
  resourceManagement: string = CONSTANTS.RESOURCE_MANAGEMENT;
  resourceForLabel: string = CONSTANTS.RESOURCE_FOR;
  statusLabel: string = CONSTANTS.STATUS;
  resourceNameLabel: string = CONSTANTS.RESOURCE_NAME_LABEL;
  expertiseLabel: string = CONSTANTS.EXPERTISE_LABEL;
  activityGroupLabel: string = CONSTANTS.ACTIVITY_GROUP_LABEL;
  activityNameLabel: string = CONSTANTS.ACTITIVY_NAME_LABEL;
  scheduleLabel: string = CONSTANTS.SCHEDULE_LABEL;
  recurringLabel: string = CONSTANTS.RECURRING_LABEL;
  oneOffLabel: string = CONSTANTS.ONE_OFF_LABEL;
  timeSlotLabel: string = CONSTANTS.TIME_SLOT_LABEL;
  mondayLabel: string = CONSTANTS.MONDAY_LABEL;
  tuesdayLabel: string = CONSTANTS.TUESDAY_LABEL;
  wednesdayLabel: string = CONSTANTS.WEDNESDAY_LABEL;
  thursdayLabel: string = CONSTANTS.THURSDAY_LABEL;
  fridayLabel: string = CONSTANTS.FRIDAY_LABEL;
  staturdayLabel: string = CONSTANTS.STATURDAY_LABEL;
  sundayLabel: string = CONSTANTS.STATURDAY_LABEL;
  activeText: string = CONSTANTS.ACTIVE;
  inactiveText: string = CONSTANTS.INACTIVE;
  dateLabel: string = CONSTANTS.DATE_LABEL;
  dayLabel: string = CONSTANTS.DAY_LABEL;
  timeLabel: string = CONSTANTS.TIME_LABEL;
  fromLabel: string = CONSTANTS.FROM_LABEL;
  toLabel: string = CONSTANTS.TO_LABEL;
  notAvailableLabel: string = CONSTANTS.NOT_AVAILABLE_LABEL;
  availableLabel: string = CONSTANTS.AVAILABLE_LABEL;
  openLabel: string = CONSTANTS.OPEN_LABEL;
  publish: string = CONSTANTS.PUBLISH;
  delete: string = CONSTANTS.DELETE;
  close: string = CONSTANTS.CLOSE;
  save: string = CONSTANTS.SAVE;
  cancel: string = CONSTANTS.CANCEL;
  bookByBALabel: string = CONSTANTS.BOOK_BY_BA;
  multiselectPlaceHolder: string = CONSTANTS.SELECT_OPTIONS;
  time_off: string = CONSTANTS.TIME_OFF;
  add_time_off: string = CONSTANTS.ADD_TIME_OFF;
  add: string = CONSTANTS.ADD;
  edit: string = CONSTANTS.EDIT;
  remove: string = CONSTANTS.REMOVE;

  country: string = '';
  countries: string[] = [];
  stores: Store[]  = [];
  storeTimes: any = {};
  storeOpenHour: number = 10;
  storeOpenMinute: number = 0;
  storeCloseHour: number = 22;
  storeCloseMinute: number = 0;
  storeTimeZone: string  = 'Asia/Singapore';

  isLoading: boolean = false;
  isEditMode: boolean = false;
  isViewMode: boolean = false;
  isFormValid: boolean = false;

  resourceForm: ResourceForm = {
    id: 0,
    storeCode: '',
    status: 0,
    name: '',
    expertise: [],
    activityGroupIds: [],
    activityNameIds: [],
    scheduleType: 0,
    fromDate: '',
    toDate: '',
    timeOff: false,
    timeOffSlots: [],
    openingSlots: [], // Maintaining opening slots
    deletedSlots: [],
    audits: [],
    bookByBAName: false,
    startDateTime: '',
    endDateTime: '',
    today: '',
  };

  orgResourceForm: ResourceForm = {
    id: 0,
    storeCode: '',
    status: 0,
    name: '',
    expertise: [],
    activityGroupIds: [],
    activityNameIds: [],
    scheduleType: 0,
    fromDate: '',
    toDate: '',
    timeOff: false,
    timeOffSlots: [],
    openingSlots: [],
    deletedSlots: [],
    audits: [],
    bookByBAName: false,
    startDateTime: '',
    endDateTime: '',
    today: '',
  };

  // multiselectPlaceHolder: string = '';
  multiselectAll: string = '';
  multisunelectAll: string = '';

  expertiseDropdownList: any[] = [];
  expertiseDropdownSettings: IDropdownSettings = {};

  activityGroupDropdownList: any[] = [];
  activityGroupDropdownSettings: IDropdownSettings = {};
  selectedActivityGroups: any[] = [];

  activityNameDropdownList: any[] = [];
  activityNameDropdownSettings: IDropdownSettings = {};
  selectedActivityNames: any[] = [];

  items: any[] = [];  // Days of Week
  timeSlots: any[] = [];  // All time slots of current resource (schedule)
  timeOffSlots: any[] = []; // Time off range slots
  tempTimeOffSlots: number = 0; // Temp of the time off range slots -> not yet publish
  fromDate!: string; // Two-way bound with input[type="date"]
  toDate!: string;   // Two-way bound with input[type="date"]
  calendarVisible = false;

  currentPage: number = 0;
  totalPages: number = 0;
  limit: number = 0;
  totalItems: number = 0;

  constructor(
    protected authService: AuthService,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private storeService: StoreService,
    private resourceService: ResourceService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
    ) {}

  ngOnInit() {
    const currentDate = new Date();
    this.resourceForm.today = currentDate.toISOString().split('T')[0];
    this.countries = this.authService.isSystemAdmin ? this.authService.countries : this.countries = [this.authService.location];
    this.country = this.authService.location;

    // this.multiselectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_OPTIONS);
    this.multiselectAll = this.translate.instant(CONSTANTS.SELECT_ALL);
    this.multisunelectAll = this.translate.instant(CONSTANTS.UNSELECT_ALL);

    this.dateLabel = this.translate.instant(CONSTANTS.DATE_LABEL);
    this.timeLabel = this.translate.instant(CONSTANTS.TIME_LABEL);
    this.fromLabel = this.translate.instant(CONSTANTS.FROM_LABEL);
    this.toLabel = this.translate.instant(CONSTANTS.TO_LABEL);

    this.expertiseDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'expertise',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      //itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.activityGroupDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'activityGroup',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.activityNameDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'activityName',
      selectAllText: this.multiselectAll,
      unSelectAllText: this.multisunelectAll,
      allowSearchFilter: true
    };

    this.items = [];
    this.timeSlots = [];
    Object.keys(localStorage).filter(x => x.startsWith('oneOffItems-page')).forEach(x => localStorage.removeItem(x));
    localStorage.removeItem('recurringItems');
    this.loadViewMode();
    // this.initializeCalendarOptions();
    }
    lastClickTime: Date | null = null;
    doubleClickDelay = 300; // Milliseconds, adjust as need ed

    initializeCalendarOptions() {
      let isEditable = false;
      if (!this.isViewMode){
        isEditable = true;
      }
       // Set the initial date
      const initialDate = this.resourceForm.fromDate ? new Date(this.resourceForm.fromDate) : new Date();// Assuming this.resourceForm.fromDate is already in a valid date format
      const toDate = new Date(this.resourceForm.toDate) ? new Date(this.resourceForm.fromDate) : new Date();; 
      const endDate = moment(this.resourceForm.toDate, 'YYYY-MM-DD');
      const endDatePlusTwoDays = endDate.add(1, 'days').format('YYYY-MM-DD');
      const weekStart = new Date(initialDate);
      weekStart.setHours(0, 0, 0, 0); // Start of the selected day
    
      const weekEnd = new Date(toDate);
      weekEnd.setDate(weekEnd.getDate() + 6); // End of the week (6 days later)
    
      const weekRange = {
        start: weekStart,
        end: weekEnd
      };
      this.calendarOptions = {
        initialView: 'timeGridWeek',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin],
        slotDuration: '00:15:00',
        slotLabelInterval: '00:15:00',
        slotMinTime: '05:30:00',
        slotMaxTime: '24:00:00',
        timeZone: this.storeTimeZone,
        titleFormat: { weekday: 'long' },
        titleRangeSeparator: 'ACTIVE',
        slotEventOverlap: false,
        // initialDate: moment.tz(initialDate, this.storeTimeZone).format('YYYY-MM-DDTHH:mm:ss'),
        // visibleRange: weekRange,
        firstDay: 0,
        views: {
          timeGridWeek: {
            type: 'timeGridWeek',
            allDaySlot: false,
            duration: { weeks: 1 },
            buttonText: 'Week',
            slotDuration: '00:15:00',
            slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
            eventTimeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
            slotLabelInterval: '00:15:00',
            slotMinTime: '05:30:00',
            slotMaxTime: '24:00:00',
            slotEventOverlap: false,
            dayHeaderContent: ({ date }, h) => {
              if (this.resourceForm.scheduleType === 1) {
                return h('div', {}, moment.tz(date, this.storeTimeZone).format('DD/MM/YYYY'))
              }else{
                return h('div', {}, moment.tz(date, this.storeTimeZone).format(`dddd`))
              }
            },
            classNames: 'hide-column-headers'
          }
        },
        headerToolbar: this.toggleHeader(),
        businessHours: this.handleBusinessHours(),
        selectable: !this.isViewMode,
        editable: !this.isViewMode,
        selectMirror: true,
        selectLongPressDelay: 0,
        events: [...this.calendarEvents, ...this.addNonBusinessHours()],
        select: this.handleDateSelect.bind(this),
        eventAllow: this.handleSelectAllow.bind(this),
        eventResize: this.handleEventResize.bind(this),
        dateClick: this.handleDateClick.bind(this),
        eventClick: this.handleEventClick.bind(this),
        eventDrop: this.handleEventDrop.bind(this),
        selectAllow: this.handleSelectAllow.bind(this), // Added selectAllow
        selectOverlap: false,
        eventOverlap: false,
        validRange: {
          end: endDatePlusTwoDays
        },
      };

    }

    moveToInitialDate() {
      // Ensure the calendar component is loaded before accessing its API
      if (this.calendarComponent) {
        const calendarApi = this.calendarComponent.getApi();
        const initialDate = moment.tz(this.resourceForm.fromDate, this.storeTimeZone).format('YYYY-MM-DD');
        calendarApi.gotoDate(initialDate);
        calendarApi.setOption('visibleRange', this.getWeekRange(initialDate));
        // const calendarApi = this.calendarComponent.getApi();
        // calendarApi.setOption('timeZone', this.storeTimeZone);
        // calendarApi.setOption('initialDate', moment.tz(new Date(this.resourceForm.fromDate), this.storeTimeZone).format('YYYY-MM-DDTHH:mm:ss'));
        // calendarApi.refetchEvents(); // This will reload the events in the calendar
      } else {
        console.error('Calendar component is not loaded');
      }
    }
    toggleHeader(){
      if (this.resourceForm.scheduleType === 1) {
        return {
          left: '',
          center: '',
          right: 'prev,next today'
        };
      } else {
        return {
          left: '',
          center: '',
          right: ''
        };
      }
    }
    handleBusinessHours(): BusinessHoursInput {
      const daysOfWeekMap = [1,2,3,4,5,6,0]; // Sunday to Saturday
  
      return daysOfWeekMap.map((dayOfWeek, index) => {
        return {
          daysOfWeek: [dayOfWeek],
          startTime: this.storeTimes.openTimes[index],
          endTime: this.storeTimes.closeTimes[index],
          startEditable: true,
          selectable: true,
          editable: true,
        };
      });
    }

    handleSelectAllow(selectInfo: any) {
      if(!this.isViewMode){
        const start = moment.utc(selectInfo.startStr).tz(this.storeTimeZone);
        const end = moment.utc(selectInfo.endStr).tz(this.storeTimeZone);
        const dayOfWeek = start.day();
      
        const businessHours = this.handleBusinessHours() as { daysOfWeek: number[], startTime: string, endTime: string }[];
        const businessHoursForDay = businessHours.find(bh => bh.daysOfWeek.includes(dayOfWeek));
      
        if (businessHoursForDay) {
          const businessStart = moment(businessHoursForDay.startTime, "HH:mm");
          const businessEnd = moment(businessHoursForDay.endTime, "HH:mm");
      
          // Extract hours and minutes from start and end
          const startHour = start.hour();
          const startMinute = start.minute();
          const endHour = end.hour();
          const endMinute = end.minute();
      
          // Extract hours and minutes from businessStart and businessEnd
          const businessStartHour = businessStart.hour();
          const businessStartMinute = businessStart.minute();
          const businessEndHour = businessEnd.hour();
          const businessEndMinute = businessEnd.minute();
      
          // Check if the start and end times are within business hours on the same day
          const isSameDay = start.isSame(end, 'day');
          const isStartTimeValid = startHour > businessStartHour || (startHour === businessStartHour && startMinute >= businessStartMinute);
          const isEndTimeValid = endHour < businessEndHour || (endHour === businessEndHour && endMinute <= businessEndMinute);
          const isOverlap = this.calendarEvents.some(event => {  // Check for overlapping events
            const eventStart = moment(event.start);
            const eventEnd = moment(event.end);
            return (start < eventEnd && end > eventStart);
          });
          // console.log("isOverlap = "+isOverlap);
          return isSameDay && isStartTimeValid && isEndTimeValid && !isOverlap;
        }
      }
      return false;
    }

    handleEventAllow(dropInfo: any, draggedEvent: any): boolean {
      const { start, end } = dropInfo;
      return !this.isBusinessHours(start, end);
    }

    isBusinessHours(start: Date, end: Date): boolean {
      const day = start.getUTCDay();
      const startTime = start.getUTCHours() + start.getUTCMinutes() / 60;
      const endTime = end.getUTCHours() + end.getUTCMinutes() / 60;
  
      const businessHours: any = {
        1: { start: 9, end: 20 }, // Monday
        2: { start: 10, end: 20 }, // Tuesday
        3: { start: 9, end: 20 }, // Wednesday
        4: { start: 9, end: 20 }, // Thursday
        5: { start: 9, end: 20 }, // Friday
      };
  
      const hours = businessHours[day];
      if (!hours) return false; // Non-business days
  
      return startTime >= hours.start && endTime <= hours.end;
    }
  
    handleDateClick(arg: any) {
      if(!this.isViewMode){
        const clickTime = new Date();
        const currentDateTime = moment().tz(this.storeTimeZone);
        const selectedDateTime = moment(arg.startStr).tz(this.storeTimeZone);
        const endDateTime = moment.tz(this.toDate, this.storeTimeZone).endOf('day');
        if (this.resourceForm.scheduleType === 1) {
          if(selectedDateTime.isBefore(currentDateTime)){
            alert('Past times cannot be selected.');
            arg.view.calendar.unselect(); 
            return;
          }else if(selectedDateTime.isSameOrAfter(endDateTime)){
            const formattedEndDateTime = endDateTime.format('DD/MM/YYYY HH:mm'); // Format this.toDate as needed
            alert('Select slots till ' + formattedEndDateTime);
            arg.view.calendar.unselect();
            return;
          }
         
        }
      
        if (this.lastClickTime && clickTime.getTime() - this.lastClickTime.getTime() < this.doubleClickDelay) {
          this.handleDateDoubleClick(arg);
        }
        this.lastClickTime = clickTime;
      }
    }
  
    handleDateDoubleClick(arg: any) {
      const clickedDate = arg.date;
      const event = this.calendarEvents.find(event => {
      const eventStart = new Date(event.start as string);
      const eventEnd = new Date(event.end as string);
      return eventStart && eventEnd && clickedDate >= eventStart && clickedDate <= eventEnd;
      });
  
      if (event && confirm('Are you sure you want to delete this event?')) {
        event['remove']();
        const index = this.selectedSlots.findIndex(slot =>
          slot.start === event.start && slot.end === event.end
        );
    
        if (index !== -1) {
          this.selectedSlots.splice(index, 1);
        }
        // this.selectedSlots = this.selectedSlots.filter(slot => slot.start !== event['startStr'] || slot.end !== event['endStr']);
      }
    }

  processNewSlot(arg: any) {
    let day = 0;
    let month = 0;
    let year = 0;
    // console.log("direct - "+ arg.startStr + ", arg.start = "+arg.start);
    // console.log( moment.tz(arg.startStr, this.storeTimeZone).utc());
    if(this.resourceForm.scheduleType) { // for one-off
      //arg.start is system local time
      const startMoment = arg.start === undefined ? moment.utc(arg.event.startStr) : moment.tz(arg.startStr, this.storeTimeZone).utc();
      day =  moment.tz((arg.startStr === undefined ? arg.event.startStr : arg.startStr) , this.storeTimeZone).date();
      month = moment.tz((arg.startStr === undefined ? arg.event.startStr : arg.startStr), this.storeTimeZone).month() + 1; // month() returns 0-11, so add 1
      year = moment.tz((arg.startStr === undefined ? arg.event.startStr : arg.startStr), this.storeTimeZone).year();
    } else { // below for recurring
      const startMoment = arg.start === undefined ? moment.utc(arg.event.startStr) : moment.tz(arg.startStr, this.storeTimeZone).utc();
      day = arg.start === undefined ? new Date(arg.event.startStr).getDay() : new Date(arg.startStr).getDay();
     
      if(day === 0) { // logic to habdle sunday morning utc time we get day = 0
        day = 7;
      }
    }
  
    const startMoment = arg.start === undefined ? moment.utc(arg.event.startStr) : moment.tz(arg.startStr, this.storeTimeZone).utc();
    const endMoment = arg.end === undefined ? moment.utc(arg.event.endStr) : moment.tz(arg.endStr, this.storeTimeZone).utc();
    let fromDate;
    let toDate;
    if(this.resourceForm.fromDate != undefined && this.resourceForm.fromDate != '') {
      fromDate = this.convertTimeSelectedWithTime(this.resourceForm.fromDate, 'T00:00:00');
      toDate = this.convertTimeSelectedWithTime(this.resourceForm.toDate, 'T23:59:59');
    } 
  
    const convertedTime = {
      day: day,
      month: month,
      year: year,
      startDateTime: startMoment.toISOString(),
      endDateTime: endMoment.toISOString(),
      scheduleId: arg.event !== undefined ? arg.event.extendedProps.scheduleId : 0,
      startHourMins: this.padWithZero(startMoment.hours()) + ":" + this.padWithZero(startMoment.minutes()) + "Z",
      endHourMins: this.padWithZero(endMoment.hours()) + ":" + this.padWithZero(endMoment.minutes()) + "Z",
      fromDate: fromDate,
      toDate: toDate
    };
    this.resourceForm.openingSlots.push(convertedTime);
  }
  extractDateComponents(dateStr: string) {
    const date = moment.tz(dateStr, this.storeTimeZone);

    // Convert to UTC
    const utcDate = date.utc();

    // Extracting components in UTC
    const year = utcDate.year();
    const month = utcDate.month() + 1; // month() returns 0-11, so we add 1
    const day = utcDate.date();

    return { year, month, day };
  }

  padWithZero(number: number): string {
    return number.toString().padStart(2, '0');
  }
   generateRandomId(){
    const timestamp = moment().format('YYYYMMDDHHmmssSSS'); // Get current timestamp
    const randomNum = Math.floor(Math.random() * 1000); // Generate a random number
    return `${timestamp}-${randomNum}`;
  }

  handleDateSelect(arg: any) {
    if(!this.isViewMode){
      const currentDateTime = moment().tz(this.storeTimeZone);
      const selectedDateTime = moment(arg.startStr).tz(this.storeTimeZone);
      if (this.resourceForm.scheduleType === 1 && selectedDateTime.isBefore(currentDateTime)) {
        alert('Past times cannot be selected.');
        arg.view.calendar.unselect(); 
        return;
      }else{
        const selectedSlot = {
          id: this.generateRandomId(),
          day: arg.start.getDay(),
          startDateTime: arg.startStr,
          endDateTime: arg.endStr,
          allDay: arg.allDay,
          slotType: 'create-event',
          overlap: false,
        };
        const isSlotAlreadySelected = this.selectedSlots.some(slot => slot.id!=undefined ? (slot.id === selectedSlot.id) : false);
    
        if (!isSlotAlreadySelected) {
          this.selectedSlots.push(selectedSlot);
          // push selected time to utc time to send to sms
          this.selectedTimeConversionInUTC(arg, 'create-event');
        }
        this.calendarEvents.push(selectedSlot);
        this.updateCalendarEvents();
      }
    }
    
  }

  private selectedTimeConversionInUTC(arg: any, event: string) {
    if(event === 'create-event'){
     
      this.processNewSlot(arg); // call when new slot is selected
    }else if(event === 'delete-event'){
      if (arg.event.extendedProps.scheduleId != undefined && arg.event.extendedProps.scheduleId != '') {

        const removeSlotIndex = this.resourceForm.openingSlots.findIndex(slot => slot.scheduleId === arg.event.extendedProps.scheduleId);
        if (removeSlotIndex !== -1) {
          this.resourceForm.openingSlots.splice(removeSlotIndex, 1);
            if(this.resourceForm.deletedSlots == null){
              this.resourceForm.deletedSlots = [];
            }
            this.resourceForm.deletedSlots.push(arg.event.extendedProps.scheduleId );
        }
        }else{ // delete newly selected slot from opening slot
          const startStr = arg.event.startStr; // Example: "2024-06-29T06:15:00+08:00"
          const endStr = arg.event.endStr;     // Example: "2024-06-29T07:00:00+08:00"

          // Convert the new slot's start and end times to match the format in openingSlots
          const newSlotStartInUTC = moment.tz(startStr, this.storeTimeZone).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          const newSlotEndInUTC = moment.tz(endStr, this.storeTimeZone).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

          const slotIndex = this.resourceForm.openingSlots.findIndex(slot => 
            slot.startDateTime === newSlotStartInUTC && 
            slot.endDateTime === newSlotEndInUTC
          );
          // Remove the slot if it's found
          if (slotIndex !== -1) {
            this.resourceForm.openingSlots.splice(slotIndex, 1);
          }
         
        }
    }
    else {
      if (arg.event.extendedProps.scheduleId != undefined && arg.event.extendedProps.scheduleId != ''
        && arg.event.extendedProps.scheduleId == arg.oldEvent.extendedProps.scheduleId) {
        const removeSlot = this.resourceForm.openingSlots.findIndex(slot => slot.scheduleId === arg.event.extendedProps.scheduleId);
        const removed = this.resourceForm.openingSlots.splice(removeSlot, 1);
        if (removed) {
          this.processNewSlot(arg); // call when old slot is changed
        }
      }else{
        const removeSlot = this.resourceForm.openingSlots.findIndex(slot => slot.day === arg.event.day && 
          slot.start == undefined ? (slot.startDateTime === arg.event.startStr) : (slot.start === arg.event.startStr));
        const removed = this.resourceForm.openingSlots.splice(removeSlot);
        if (removed) {
          this.processNewSlot(arg); // call when old slot is changed
        }
      }
    }
  }

  
  
  handleEventResize(arg: any) {
    if(!this.isViewMode){
      let isSlotAlreadySelected = -1;

      const selectedSlot = {
        day: arg.event.start.getDay(),
        start:  arg.event.startStr,
        end:  arg.event.endStr,
        allDay: arg.event.allDay,
        scheduleId: arg.event.extendedProps.scheduleId,
        slotType: 'create-event',
        overlap: false,
        eventOverlap: false,
        id: arg.event.id,
      };

      if(selectedSlot.scheduleId == undefined && selectedSlot.id != undefined){
        isSlotAlreadySelected = this.selectedSlots.findIndex(slot => slot.id === selectedSlot.id);
      } else{
        isSlotAlreadySelected = this.selectedSlots.findIndex(slot => slot.scheduleId === selectedSlot.scheduleId);
      }
    
      if(isSlotAlreadySelected !== -1){
        // Update the `selectedSlots` array
        this.selectedSlots.splice(isSlotAlreadySelected, 1);
        
      }
      this.selectedSlots.push(selectedSlot);

      // push selected sg time to utc time to send to sms
      this.selectedTimeConversionInUTC(arg, 'resize');

      this.calendarEvents.push(this.selectedSlots);
      this.updateCalendarEvents();
    }
  }
  
    handleEventClick(arg: any) {
      if(!this.isViewMode){
        const event = arg.event;
        const newSlot = { start: event.startStr, end: event.endStr, allDay: event.allDay, extendedProps: event.extendedProps, editable: true };

        if (confirm('Are you sure you want to delete this event?')) {
          arg.event.remove();
          this.selectedTimeConversionInUTC(arg, 'delete-event');
          let slotIndex = -1;
          if (arg.event.extendedProps.scheduleId != undefined && arg.event.extendedProps.scheduleId != '') {

            slotIndex = this.selectedSlots.findIndex(slot => slot.scheduleId === arg.event.extendedProps.scheduleId);
          }else{
             slotIndex = this.selectedSlots.findIndex(slot => {
              const slotStart = slot.startDateTime || this.convertToTimezone(slot.start, this.storeTimeZone);
              const slotEnd = slot.endDateTime || this.convertToTimezone(slot.end, this.storeTimeZone);
              return slotStart === newSlot.start && slotEnd === newSlot.end;
            });  
          }
          
          // Remove the slot if it's found
          if (slotIndex !== -1) {
            this.selectedSlots.splice(slotIndex, 1);
          }
         
          this.calendarEvents.push(this.selectedSlots);
          this.updateCalendarEvents();
          // this.selectedSlots = this.selectedSlots.filter(slot => slot.start !== newSlot.start || slot.end !== newSlot.end);
        }
      }
    }
    convertToTimezone = (datetime: string, timezone: string) => {
      return moment.tz(datetime, "YYYY-MM-DDTHH:mm:ssZ", "UTC").tz(timezone).format();
    };
    handleEventDrop(arg: any) {
      if(!this.isViewMode){
        const event = arg.event;
        const newSlot = { start: event.startStr, end: event.endStr };
        this.selectedSlots = this.selectedSlots.map(slot => slot.start === arg.oldEvent.startStr && slot.end === arg.oldEvent.endStr ? newSlot : slot);
        event.setProp('title', `${this.formatTime(event.start)} - ${this.formatTime(event.end)}`);
        this.updateCalendarEvents();
      }
    }
  
    loadSelectedSlots() {
      this.selectedSlots.forEach(slot => {
        this.calendarEvents.push({ start: slot.start, end: slot.end, display: 'background', backgroundColor: '#32a84a' });
      });
      this.updateCalendarEvents();
    }
  
    addNonBusinessHours() {
      const businessHours = this.handleBusinessHours() as { daysOfWeek: number[], startTime: string, endTime: string }[];
  
      const nonBusinessEvents: { daysOfWeek: number[]; startTime: string; endTime: string; display: string; backgroundColor: string; }[] = [];
      businessHours.forEach(day => {
        // Add non-business hours before business hours
        nonBusinessEvents.push({
          daysOfWeek: day.daysOfWeek,
          startTime: '00:00',
          endTime: day.startTime,
          display: 'background',
          backgroundColor: 'silver',
        });
  
        // Add non-business hours after business hours
        nonBusinessEvents.push({
          daysOfWeek: day.daysOfWeek,
          startTime: day.endTime,
          endTime: '24:00',
          display: 'background',
          backgroundColor: 'silver',
        });
      });
  
      return nonBusinessEvents;
    }

    // Function to parse the time zone string and extract the offset
    getTimeZoneOffset() {
      this.storeTimeZone = '(GMT+08:00)Singapore';
      const match = this.storeTimeZone.match(/\(GMT([+-]\d{2}):(\d{2})\)/);
      if (match) {
          const hours = parseInt(match[1], 10);
          const minutes = parseInt(match[2], 10);
          return { hours, minutes };
      }
      return null;
    }
    prePopulateTimeSlots() {
      const timeZone = this.storeTimeZone; // Ensure this is set correctly
     
      const events = this.resourceForm.openingSlots.map((slot: any) => {
        // console.log(" sms time - "+ slot.year +"-"+ slot.month +"-"+ slot.day +"-"+ slot.startHourMins +"-"+ slot.endHourMins);
        let startDate: string;
        let endDate: string;

        if (this.resourceForm.scheduleType) {// this is for one-off
          // Combine date and time, then parse as UTC
          const utcStart = `${slot.year}-${this.padWithZero(slot.month)}-${this.padWithZero(slot.day)}T`+this.convertUTCToTimezone(slot.startHourMins, timeZone);
          const utcEnd = `${slot.year}-${this.padWithZero(slot.month)}-${this.padWithZero(slot.day)}T`+this.convertUTCToTimezone(slot.endHourMins, timeZone);
          // const [hour, minute] = slot.startHourMins.split(':').map(Number);
          // const [endHour, endMinute] = slot.endHourMins.split(':').map(Number);

          // // Create a moment object in UTC with today's date
          // const utcStartDateTime = moment.tz(utcStart).hours(hour).minutes(minute).seconds(0);
          // const  utcEndDateTime =  moment.tz(utcEnd).hours(endHour).minutes(endMinute).seconds(0);//parseInt(slot.endHourMins.split(':')[0]);
          // const startHour = parseInt(slot.startHourMins.split(':')[0]);
    
          // Adjust for next-day end time
          // const momentEndDate = moment.utc(utcEnd);
          // if (endHour < startHour) {
          //   momentEndDate.add(1, 'day'); // Adjust to next day
          // }
          //console.log(utcStart);
          startDate = moment(utcStart).format('YYYY-MM-DDTHH:mm:ss');
          endDate = moment(utcEnd).format('YYYY-MM-DDTHH:mm:ss');
    
        } else {
          const start = this.convertUTCToTimezone(slot.startHourMins, timeZone);
          const end = this.convertUTCToTimezone(slot.endHourMins, timeZone)
          const utcTimes = this.getDateForDayOfWeek(slot.day, start, end);
          if (utcTimes.startLocalTime && utcTimes.endLocalTime) {
            startDate = utcTimes.startLocalTime;
            endDate = utcTimes.endLocalTime;
          } else {
            startDate = '';
            endDate = '';
          }
        }
    
        return {
          scheduleId: slot.scheduleId,
          title: 'Open',
          start: startDate,
          end: endDate,
          allDay: slot.allDay,
          id: this.generateRandomId(),
        };
      });
    
      if (events.length > 0) {
        events.forEach((event: any) => {
          this.selectedSlots.push(event);
        });
      }
    
      console.log('prepopulate =', this.selectedSlots);
      this.calendarOptions.events = events;
    }
    convertUTCToTimezone(utcTime: string, timeZone: string) {
      // Create a moment object in UTC
      const utcMoment = moment.utc(utcTime, "HH:mm");
    
      // Convert to the specified timezone
      const localTime = utcMoment.clone().tz(timeZone);
    
      // Format the result to "HH:mm"
      return localTime.format('HH:mm');
    }
    
    getCurrentWeekRange(today: moment.Moment) {
      // Start of the week (e.g., Monday) for today's date
      const startOfWeek = today.clone().startOf('week');
      const endOfWeek = startOfWeek.clone().add(6, 'days');
      
      return { startOfWeek, endOfWeek };
    }
    getDateForDayOfWeek(dayOfWeek: number, startHourMins: string, endHourMins: string) {
      if(dayOfWeek === 7) { // logic to handle sunday=7 coming from sms 
        dayOfWeek = 0; // set sunday = 0 in calender as isoWeekday  (Sunday = 0, Saturday = 6) Get the day of the week
      } 

      // Get the current date in the specified time zone
    const currentDate = moment().tz(this.storeTimeZone);
    // console.log("currentdate" + currentDate.format('YYYY-MM-DDTHH:mm:ss[Z]') + ", day - "+currentDate.day());
    let addWeek = 0;
    if(currentDate.isoWeekday() == 7){
      addWeek = 1;
    }
    let weekStartDate = currentDate.clone().add(addWeek, 'week'); // Get Monday

      // Find the start of the current week (Monday)
      const targetDate = addWeek === 0
      ? currentDate.clone().isoWeekday(dayOfWeek) // Current week
      : weekStartDate.clone().isoWeekday(dayOfWeek); // Next week if addWeek is 1
    // const startOfWeek = currentDate.clone().startOf('week');
    // console.log("dayOfWeek = "+dayOfWeek);


      // console.log("targetDate" + currentDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
      // const { startLocal, endLocal } = this.convertUtcToRequiredTime(this.storeTimeZone, targetDate, startHourMins, endHourMins);
      // // Parse `startLocal` and `endLocal` into moment objects for comparison
      // let startMoment = moment.tz(startLocal, this.storeTimeZone);
      // let endMoment = moment.tz(endLocal, this.storeTimeZone);
      // console.log("startMoment = "+startMoment.format('YYYY-MM-DDTHH:mm:ss[Z]'));

      const startLocalTime = `${targetDate.format('YYYY-MM-DD')}T${startHourMins}`;

      const endLocalTime = `${targetDate.format('YYYY-MM-DD')}T${endHourMins}`;
      // console.log("startLocalTime - "+ startLocalTime + ", endLocalTime - "+endLocalTime);
      return { startLocalTime, endLocalTime };
    }
    convertUtcToRequiredTime(timeZone: string, date: moment.Moment, startHourMins: string, endHourMins: string) {
       // Convert timestamp to moment object if necessary

      const baseDate = moment.isMoment(date) ? date : moment(date);

      // Combine the date with start and end times
      const startDateTime = `${baseDate.format('YYYY-MM-DD')}T${startHourMins}`;
      const endDateTime = `${baseDate.format('YYYY-MM-DD')}T${endHourMins}`;
      // console.log("startDateTime - " + startDateTime + ", endDateTime- "+endDateTime);
      // Parse start and end hours/minutes
      // const [startHour, startMinute] = startHourMins.split(':').map(Number);
      // const [endHour, endMinute] = endHourMins.split(':').map(Number);

      // Adjust for next-day end time
      // const endDate = baseDate.clone(); // Clone to avoid mutating original date
      // console.log(endDate + "  - END DATE");
      // if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
      //   endDate.add(1, 'day'); // Move to the next day
      // }
      // const endDateTime = `${endDate.format('YYYY-MM-DD')}T${endHourMins}`;

      // Convert to the specified timezone
      const startLocal = startDateTime;
      const endLocal = endDateTime;

      return { startLocal, endLocal };
    }
  
    updateCalendarEvents() {
      const events = this.selectedSlots.map((slot: any) => {
        const start =  slot.startDateTime != undefined ? slot.startDateTime : slot.start;
        const end = slot.endDateTime != undefined ? slot.endDateTime : slot.end;

        return {
          scheduleId: slot.scheduleId,
          start: start,
          end: end,
          allDay: slot.allDay,
          id: slot.id,
        };
      });
      this.calendarOptions.events = events;
    }

    // Function to calculate the start and end dates of the week containing the initial date
    getWeekRange(initialDate: moment.MomentInput) {
      const startOfWeek = moment(initialDate).startOf('week');
      const endOfWeek = moment(initialDate).endOf('week');
      return { start: startOfWeek.format('YYYY-MM-DD'), end: endOfWeek.format('YYYY-MM-DD') };
    }
  
    formatTime(date: Date): string {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

  ngAfterViewInit() {
    if (!this.resourceForm.openingSlots.length) {
      $('#add-time-off-0').hide();
    }
    this.cdr.detectChanges(); 
  }

  private loadViewMode() {
    // Get path variables
    this.route.params.subscribe(params => {
      this.country = params['storeCountry'];
      if (this.country) {
        this.onSelectCountry(this.country);
      }

      this.resourceForm.storeCode = params['storeCode'];
      let id = params['id'];
      this.fetchStoreProperties(this.resourceForm.storeCode, id);

      // Is create mode
      if (!id) {
        this.title.setTitle(CONSTANTS.NEW_RESOURCE);
        this.resourceForm.scheduleType == 0;
        return;
      // Edit/View mode
      // If there's an ID provided, fill forms with corresponding user data
      } else {
        if(this.router.url.includes('edit')) {
          this.title.setTitle(CONSTANTS.EDIT_RESOURCE);
          this.isEditMode = true;
        } else if (this.router.url.includes('view')) {
          this.title.setTitle(CONSTANTS.VIEW_TERMINAL);
          this.isViewMode = true;
        }
        localStorage.setItem('getScheduleItemPage', 'none');

        if (this.resourceForm.scheduleType) {
          localStorage.setItem('resourceFromDate', this.resourceForm.fromDate);
        }
        if(this.resourceForm.fromDate != undefined && this.resourceForm.fromDate != '') {
          this.resourceForm.fromDate = this.convertUTCToTimeZone(this.resourceForm.fromDate, this.storeTimeZone);
          this.resourceForm.toDate = this.convertUTCToTimeZone(this.resourceForm.toDate, this.storeTimeZone);
        } 
      }
    });
  }

  private fetchStoreProperties(storeCode: string, id: number) {
    this.isLoading = true;
    if (storeCode === localStorage.getItem('onSelectPropertiesByStoreCode')) {
      this.storeTimes = JSON.parse(localStorage.getItem('storeTimes') as string);
      this.storeOpenHour = Number(localStorage.getItem('storeOpenHour') as string);
      this.storeOpenMinute = Number(localStorage.getItem('storeOpenMinute') as string);
      this.storeCloseHour = Number(localStorage.getItem('storeCloseHour') as string);
      this.storeCloseMinute = Number(localStorage.getItem('storeCloseMinute') as string);
      this.storeTimeZone = localStorage.getItem('storeTimeZone') as string;
      this.activityGroupDropdownList = JSON.parse(localStorage.getItem('activityGroupDropdownList') as string);
      this.expertiseDropdownList = JSON.parse(localStorage.getItem('expertiseDropdownList') as string);
      if (id) {
        this.getResourceById(id);
      }
      this.isLoading = false;
    } else if (storeCode === localStorage.getItem('onSelectResourceStoreCode') as string) {
      this.activityGroupDropdownList = JSON.parse(localStorage.getItem('activityGroupDropdownList') as string);
      forkJoin(
        this.resourceService.fetchStoreTimes(storeCode),
        this.resourceService.fetchExpertise()
      ).subscribe(
        res => {
          if (res[0] && res[0].openTimes) {
            this.loadStoreTimes(res[0]);
            this.storeTimeZone = res[0].time_zone;
            this.expertiseDropdownList = res[1].body.expertise;
            localStorage.setItem('onSelectPropertiesByStoreCode', storeCode);
            localStorage.setItem('storeTimes', JSON.stringify(res[0]));
            localStorage.setItem('storeOpenHour', this.storeOpenHour + '');
            localStorage.setItem('storeOpenMinute', this.storeOpenMinute + '');
            localStorage.setItem('storeCloseHour', this.storeCloseHour + '');
            localStorage.setItem('storeCloseMinute', this.storeCloseMinute + '');
            localStorage.setItem('storeTimeZone', res[0].time_zone + '')
            localStorage.setItem('activityGroupDropdownList', JSON.stringify(this.activityGroupDropdownList));
            localStorage.setItem('expertiseDropdownList', JSON.stringify(this.expertiseDropdownList));
            if (id) {
              this.getResourceById(id);
            }
          } else {
            this.resourceForm.storeCode = (localStorage.getItem('onSelectPropertiesByStoreCode') as string) ? (localStorage.getItem('onSelectPropertiesByStoreCode') as string) : '';
            this.notificationsService.error(
              CONSTANTS.ERROR,
              CONSTANTS.RESOURCE_FETCH_STORE_FAIL,
              {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
             }
            );
          }
          this.isLoading = false;
       },
        err => {
          Bugsnag.notify(err);
          this.isLoading = false;
        }
      );
    } else {
      forkJoin(
        this.resourceService.fetchStoreTimes(storeCode),
        this.resourceService.fetchActivityGroups('', storeCode),
        this.resourceService.fetchExpertise()
      ).subscribe(
        res => {
          if (res[0] && res[0].openTimes && res[1].body.activityGroups.length) {
            this.loadStoreTimes(res[0]);
            this.storeTimeZone = res[0].time_zone;
            this.activityGroupDropdownList = res[1].body.activityGroups;
            this.expertiseDropdownList = res[2].body.expertise;
            localStorage.setItem('onSelectPropertiesByStoreCode', storeCode);
            localStorage.setItem('storeTimes', JSON.stringify(res[0]));
            localStorage.setItem('storeOpenHour', this.storeOpenHour + '');
            localStorage.setItem('storeOpenMinute', this.storeOpenMinute + '');
            localStorage.setItem('storeCloseHour', this.storeCloseHour + '');
            localStorage.setItem('storeCloseMinute', this.storeCloseMinute + '');
            localStorage.setItem('storeTimeZone', this.storeTimeZone + '');
            localStorage.setItem('activityGroupDropdownList', JSON.stringify(this.activityGroupDropdownList));
            localStorage.setItem('expertiseDropdownList', JSON.stringify(this.expertiseDropdownList));
            if (id) {
              this.getResourceById(id);
            }
          } else {
            this.resourceForm.storeCode = (localStorage.getItem('onSelectPropertiesByStoreCode') as string) ? (localStorage.getItem('onSelectPropertiesByStoreCode') as string) : '';
            this.notificationsService.error(
              CONSTANTS.ERROR,
              CONSTANTS.RESOURCE_FETCH_STORE_FAIL,
              {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
             }
            );
          }
          this.isLoading = false;
       },
        err => {
          Bugsnag.notify(err);
          this.isLoading = false;
        }
      );
    }
  }

  private fetchActivities() {
    if (!this.resourceForm.activityGroupIds || !this.resourceForm.activityGroupIds.length) {
      this.activityNameDropdownList = [];
      this.resourceForm.activityNameIds = [];
      this.selectedActivityNames = [];
    } else {
      if (this.resourceForm.storeCode) {
        this.isLoading = true;
        this.resourceService.fetchActivityNames(this.resourceForm.storeCode, this.resourceForm.activityGroupIds).subscribe(
          res => {
            if (res.body.activityNames.length) {
              this.activityNameDropdownList = res.body.activityNames;
              if (this.resourceForm.activityNameIds.length) {
                const allActivityNameIds = this.activityNameDropdownList.map((e: any) => {return e.id});
                this.resourceForm.activityNameIds = this.resourceForm.activityNameIds.filter(i => allActivityNameIds.includes(i));
                this.selectedActivityNames = this.selectedActivityNames.length ? this.selectedActivityNames.filter(a => this.resourceForm.activityNameIds.includes(a.id)) : this.activityNameDropdownList.filter(a => this.resourceForm.activityNameIds.includes(a.id));
              }
            } else {
              this.notificationsService.error(
                CONSTANTS.ERROR,
                CONSTANTS.RESOURCE_FETCH_STORE_FAIL,
                {
                  timeOut: 5000,
                  showProgressBar: true,
                  pauseOnHover: true,
                  clickToClose: true
                }
              );
            }
            this.isLoading = false;
          },
          err => {
            Bugsnag.notify(err);
            this.isLoading = false;
          }
        );
      }
    }
  }

  private loadStoreTimes(otr: any) {
    this.storeTimes = otr;
    this.storeOpenHour = Number(otr.openTimes[0].split(':')[0])
    this.storeOpenMinute = Number(otr.openTimes[0].split(':')[1])
    this.storeCloseHour = Number(otr.closeTimes[0].split(':')[0])
    this.storeCloseMinute = Number(otr.closeTimes[0].split(':')[1])
    for (let i = 1; i < 6; i++) {
      if (this.storeOpenHour > Number(otr.openTimes[i].split(':')[0])) {
        this.storeOpenHour = Number(otr.openTimes[i].split(':')[0]);
        this.storeOpenMinute = Number(otr.openTimes[i].split(':')[1])
      } else if (this.storeOpenHour == Number(otr.openTimes[i].split(':')[0]) && this.storeOpenMinute > Number(otr.openTimes[i].split(':')[1])) {
        this.storeOpenMinute = Number(otr.openTimes[i].split(':')[1])
      }
      if (this.storeCloseHour < Number(otr.closeTimes[i].split(':')[0])) {
        this.storeCloseHour = Number(otr.closeTimes[i].split(':')[0]);
        this.storeCloseMinute = Number(otr.closeTimes[i].split(':')[1])
      } else if (this.storeCloseHour == Number(otr.closeTimes[i].split(':')[0]) && this.storeCloseMinute < Number(otr.closeTimes[i].split(':')[1])) {
        this.storeCloseMinute = Number(otr.closeTimes[i].split(':')[1])
      }
    }
  }

  onDateChangeFrom() {
    // if (this.resourceForm.fromDate && new Date(this.resourceForm.fromDate)) {
      this.isFormValid = true;
      this.clearError('fromDate');
      if(this.resourceForm.fromDate){
        this.fromDate = this.resourceForm.fromDate;
        this.initializeCalendarOptions();
        if(this.selectedSlots.length > 0){
          // alert("Changing the date, you would require to reselect the slots");
          this.calendarEvents.push(this.selectedSlots);
          this.updateCalendarEvents();
        }
        if(this.resourceForm.toDate){
          this.moveToInitialDate();
        }
        
      }
      if (this.resourceForm.toDate && new Date(this.resourceForm.toDate)) {
        this.validateDateRange(new Date(this.resourceForm.fromDate), new Date(this.resourceForm.toDate));
      }
    // } else {
    //   this.resourceForm.fromDate = '';
    //   this.timeSlots = [];
    //   this.isFormValid = false;
    //   this.showError('fromDate', CONSTANTS.ERROR_REQUIRE_TEXT);
    //   this.clearError('toDate');
    // }

  }

  onDateChangeTo() {
    // if (this.resourceForm.toDate && new Date(this.resourceForm.toDate)) {
      this.validateDateRange(new Date(this.resourceForm.fromDate), new Date(this.resourceForm.toDate));
      this.fromDate = new Date(this.resourceForm.fromDate).toString();
      this.toDate = new Date(this.resourceForm.toDate).toString();
      if (this.resourceForm.toDate){
        this.toDate = this.resourceForm.toDate;
        this.initializeCalendarOptions();
        if(this.selectedSlots.length > 0){
          // alert("Changing the date, you would require to reselect the slots");
          this.calendarEvents.push(this.selectedSlots);
          this.updateCalendarEvents();
        }
        // this.reloadCalendar();
      }
      
    // } else {
    //   this.resourceForm.toDate = '';
    //   this.timeSlots = [];
    //   this.isFormValid = false;
    //   this.showError('toDate', CONSTANTS.ERROR_REQUIRE_TEXT);
    //   this.clearError('fromDate');
    // }
  }

  private validateDateRange(fromDate: any, toDate: any) {
    this.items.length = 7
    // if (fromDate.getTime() > toDate.getTime()) {
    //   this.isFormValid = false;
    //   this.showError('toDate', CONSTANTS.ERROR_DATE_RANGE);
    //   this.clearError('fromDate');
    // } else {
      const betweenDays = (toDate.getTime() - fromDate.getTime())/(1000 * 60 * 60 * 24) + 1;
      if (betweenDays > 91) {
        this.isFormValid = false;
        this.showError('toDate', CONSTANTS.ERROR_DATE_RANGE_MORE_THAN_3_MONTHS);
        this.clearError('fromDate');
      } else if (!this.resourceForm.scheduleType && betweenDays < 6) {
        this.isFormValid = false;
        this.showError('toDate', CONSTANTS.ERROR_DATE_RANGE_LESS_THAN_7);
        this.clearError('fromDate');
      } else {
        this.isFormValid = true;
        this.clearError('fromDate');
        this.clearError('toDate');
        if (this.resourceForm.scheduleType && localStorage.getItem('resourceFromDate') && (fromDate.getTime() < (new Date(localStorage.getItem('resourceFromDate') as string).getTime()))) {
          Object.keys(localStorage).filter(x => x.startsWith('oneOffItems-page')).forEach(x => localStorage.removeItem(x));
        }
        this.generateWeekView(this.currentPage);
        this.limit = this.items.length;
        this.totalItems = this.totalPages * this.limit;
      }
    // }
  }

  generateWeekView(page: number) {
    // this.resourceForm.scheduleType = 1;
    // if (this.resourceForm.scheduleType) {
    //   let firstMonday = this.getMondayDateOfWeek(new Date(this.resourceForm.fromDate));
    //   firstMonday.setHours(0,0,0,0);
    //   let lastMonday = this.getMondayDateOfWeek(new Date(this.resourceForm.toDate));
    //   lastMonday.setHours(0,0,0,0);
    //   this.totalPages = ((lastMonday.valueOf() - firstMonday.valueOf()) / (1000 * 3600 * 24 * 7)) + 1;
    //   let currentDate = new Date();
    //   currentDate.setHours(0,0,0,0);
    //   let md = this.getMondayDateOfWeek(currentDate);
    //   if (firstMonday > md) {
    //     md = firstMonday;
    //   } else if (lastMonday < md) {
    //     md = lastMonday;
    //   }
    //   if (page && page <= this.totalPages) {
    //     let fromDate = new Date(this.resourceForm.fromDate);
    //     fromDate.setHours(0,0,0,0);
    //     md = this.getMondayDateOfWeek(fromDate);
    //     md.setDate(md.getDate() + ((page-1) * 7));
    //     this.currentPage = page;
    //   } else {
    //     this.currentPage = ((md.valueOf() - firstMonday.valueOf()) / (1000 * 3600 * 24 * 7)) + 1;
    //   }

    //   let cmd = new Date(md.getTime());
    //   this.mondayLabel = `${this.translate.instant(CONSTANTS.MONDAY_LABEL)} (${moment(md).format('MM/DD/YYYY')})`;
    //   this.tuesdayLabel = `${this.translate.instant(CONSTANTS.TUESDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;
    //   this.wednesdayLabel = `${this.translate.instant(CONSTANTS.WEDNESDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;
    //   this.thursdayLabel = `${this.translate.instant(CONSTANTS.THURSDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;
    //   this.fridayLabel = `${this.translate.instant(CONSTANTS.FRIDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;
    //   this.staturdayLabel = `${this.translate.instant(CONSTANTS.STATURDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;
    //   this.sundayLabel = `${this.translate.instant(CONSTANTS.SUNDAY_LABEL)} (${moment(cmd.setDate(cmd.getDate() + 1)).format('MM/DD/YYYY')})`;

    //   this.generateOneOffTimeSlots(md);
    // } else {
      // this.mondayLabel = this.translate.instant(CONSTANTS.MONDAY_LABEL);
      // this.tuesdayLabel = this.translate.instant(CONSTANTS.TUESDAY_LABEL);
      // this.wednesdayLabel = this.translate.instant(CONSTANTS.WEDNESDAY_LABEL);
      // this.thursdayLabel = this.translate.instant(CONSTANTS.THURSDAY_LABEL);
      // this.fridayLabel = this.translate.instant(CONSTANTS.FRIDAY_LABEL);
      // this.staturdayLabel = this.translate.instant(CONSTANTS.STATURDAY_LABEL);
      // this.sundayLabel = this.translate.instant(CONSTANTS.SUNDAY_LABEL);

      this.generateRecurringTimeSlots();
    // }
  }

  /*private getMondayDateOfWeek(date: any) {
    const currentDayOfWeek = date.getDay();
    const mondayDistanceDays = (currentDayOfWeek > 0) ? currentDayOfWeek - (2*currentDayOfWeek - 1) : -6;
    date.setDate(date.getDate() + mondayDistanceDays);
    return date;
  }*/

  /*private generateOneOffTimeSlots(md: any) {
    if (localStorage.getItem(`oneOffItems-page${this.currentPage}`)) {
      this.items = JSON.parse(localStorage.getItem(`oneOffItems-page${this.currentPage}`) as string)
    } else {
      let fromDate = new Date(this.resourceForm.fromDate);
      fromDate.setHours(0,0,0,0);
      let toDate = new Date(this.resourceForm.toDate);
      toDate.setHours(0,0,0,0);
      for (let sd = md > fromDate ? new Date(md.getTime()) : new Date(fromDate.getTime()); sd.getDay() >= 0 || sd >= toDate; sd.setDate(sd.getDate() + 1)) {
        this.generateDateTimeSlots(sd);
        if (sd.getDay() === 0 || sd >= toDate) {
          this.items = [];
          for (let bd = new Date(md.getTime()); bd.getDay() >= 0; bd.setDate(bd.getDate() + 1)) {
            this.generateDateItems(bd);
            if (bd.getDay() === 0) {
              localStorage.setItem(`oneOffItems-page${this.currentPage}`, JSON.stringify(this.items));
              break;
            }
          }
          break;
        }
      }

      if (this.isEditMode || this.isViewMode) {
        if (localStorage.getItem('getScheduleItemPage') === 'ready') {
          this.isLoading = true;
          // this.resourceService.getScheduleItemPage(this.resourceForm.id, moment(md).format('YYYY-MM-DD'), this.resourceForm.scheduleType).subscribe(
          //   res => {
          //     if (res.length) {
          //       res.forEach((o: any) => {
          //         for (let t of o.ts) {
          //           const weekday = CODINGCONSTANTS.DAYS_OF_WEEK_SUNDAY_TO_STAURDAY[new Date(o.id).getDay()];
          //           let theItem = this.items.find((it: any) => (`${it.timeSlot}:00` === t && it[`${weekday}Date`] === o.id));
          //           if (theItem) theItem[`${weekday}`] = 1;
          //           let theTimeSlot = this.timeSlots.find((it: any) => it.id === `${o.id}T${t}`)
          //           if (theTimeSlot) theTimeSlot.status = 1;
          //         }

          //         let theOx = this.resourceForm.openingSlots.find((ox: any) => ox.id === o.id);
          //         if (!theOx) {
          //           this.resourceForm.openingSlots.push(o);
          //         } else {
          //           for (let t of o.ts) {
          //             theOx.ts.push(t);
          //           }
          //         }
          //       });
          //       localStorage.removeItem(`oneOffItems-page${this.currentPage}`);
          //       localStorage.setItem(`oneOffItems-page${this.currentPage}`, JSON.stringify(this.items));
          //     }
          //     this.isLoading = false;
          //   },
          //   err => {
          //     Bugsnag.notify(err);
          //     this.isLoading = false;
          //   }
          // );
        } else {
          if (this.resourceForm.openingSlots.length) {
            // this.resourceForm.openingSlots.forEach((o: any) => {
            //   // for (let t of o.) {
            //     const weekday = CODINGCONSTANTS.DAYS_OF_WEEK_MONDAY_TO_SUNDAY[new Date(o.id).getDay()];
            //     let theItem = this.items.find((it: any) => (`${it.timeSlot}:00` === o.startDateTime && it[`${weekday}Date`] === o.id));
            //     if (theItem) theItem[`${weekday}`] = 1;
            //     let theTimeSlot = this.timeSlots.find((it: any) => it.id === `${o.id}T${o.startDateTime}`)
            //     if (theTimeSlot) theTimeSlot.status = 1;
            //   // }
            // });
          }
          localStorage.setItem(`oneOffItems-page${this.currentPage}`, JSON.stringify(this.items));
          localStorage.setItem('getScheduleItemPage', 'ready');
        }
      } 
    }
  }*/

  private generateRecurringTimeSlots() {
    if (localStorage.getItem('recurringItems')) {
      this.items = JSON.parse(localStorage.getItem('recurringItems') as string)
    } else {
      const w = CODINGCONSTANTS.DAYS_OF_WEEK_MONDAY_TO_SUNDAY;
      for (let i=0; i < w.length; i++) {
        this.generateDayTimeSlots(w[i]);
        if (i === w.length - 1) {
          this.items = [];
          for (let j=0; j < w.length; j++) {
            this.generateDayItems(w[j]);
            if (j === w.length - 1) {
              localStorage.setItem('recurringItems', JSON.stringify(this.items));
            }
          }
        }
      }

      if ((this.isEditMode || this.isViewMode) && this.resourceForm.fromDate != undefined && this.resourceForm.fromDate != '') {
        // this.resourceForm.openingSlots.forEach((o: any) => {
        //   // for (let t of o.ts) {
        //     this.items.find((it: any) => (`${it.timeSlot}:00` === o.startDateTime))[`${o.scheduleId}`] = 1;
        //     localStorage.setItem('recurringItems', JSON.stringify(this.items));
        //     this.timeSlots.find((it: any) => it.id === `${o.scheduleId}T${o.startDateTime}`).status = 1;
        //   // }
        // });
        this.resourceForm.fromDate = this.convertUTCToTimeZone(this.resourceForm.fromDate, this.storeTimeZone);
        this.resourceForm.toDate = this.convertUTCToTimeZone(this.resourceForm.toDate, this.storeTimeZone);
      } 
    }
  }

  /*private generateDateTimeSlots(d: any) {
    for (let h = this.storeOpenHour; h <= this.storeCloseHour; h++) {
      for (let m = 0; m < 60; m = m+15) {
        if (h === this.storeOpenHour) {
          if (m >= this.storeOpenMinute) {
            this.pushOneOffSlot(d, h, m);
          }
        } else if (h < this.storeCloseHour) {
          this.pushOneOffSlot(d, h, m);
        } else if (m < this.storeCloseMinute) {
          this.pushOneOffSlot(d, h, m);
        }
      }
    }
  }*/

  private generateDayTimeSlots(d: any) {
    for (let h = this.storeOpenHour; h <= this.storeCloseHour; h++) {
      for (let m = 0; m < 60; m = m+15) {
        if (h === this.storeOpenHour) {
          if (m >= this.storeOpenMinute) {
            this.pushRecurringSlot(d, h, m);
          }
        } else if (h < this.storeCloseHour) {
          this.pushRecurringSlot(d, h, m);
        } else if (m < this.storeCloseMinute) {
          this.pushRecurringSlot(d, h, m);
        }
      }
    }
  }

  /*private pushOneOffSlot(sd: any, h: number, m: number) {
    const ts = this.timeSlots.find((it: any) => it.id == `${moment(sd).format('YYYY-MM-DD')}T${this.getTimeSlotStr(h, m)}:00`);
    if (!ts) {
      let i = 0; //storeTimesIndex
      if (sd.getDay() === 0) {
        i = 1;
      } else {
        i = sd.getDay() - 1;
      }
      // this.storeTimes.openTimes[i] = "[ \"10:00", "10:00", "10:00", "10:00", "10:00", "10:00", "10:00\"]";
      // this.storeTimes.closeTimes[i] ="[ \"22:00", "22:00", "22:00", "22:00", "22:00", "22:00", "22:00\"]";
      const theStoreOpenHour = Number(9);
      const theStoreOpenMinute = Number(0);
      const theStoreCloseHour = Number(22);
      const theStoreCloseMinute = Number(30);
      if ((h > theStoreOpenHour || (h === theStoreOpenHour && m >= theStoreOpenMinute)) && (h < theStoreCloseHour || (h === theStoreCloseHour && m < theStoreCloseMinute))) {
        this.timeSlots.push(
          {
            id: `${moment(sd).format('YYYY-MM-DD')}T${this.getTimeSlotStr(h, m)}:00`,
            status: 0
          }
        );
      }
    }
  }*/

  private pushRecurringSlot(sd: any, h: number, m: number) {
    //const ts = this.timeSlots.find((it: any) => it.id == `${sd}T${this.getTimeSlotStr(h, m)}:00`);
    //if (!ts) {
      const i = CODINGCONSTANTS.DAYS_OF_WEEK_MONDAY_TO_SUNDAY.indexOf(sd); //storeTimesIndex
      const theStoreOpenHour = Number(this.storeTimes.openTimes[i].split(':')[0]);
      const theStoreOpenMinute = Number(this.storeTimes.openTimes[i].split(':')[1]);
      const theStoreCloseHour = Number(this.storeTimes.closeTimes[i].split(':')[0]);
      const theStoreCloseMinute = Number(this.storeTimes.closeTimes[i].split(':')[1]);
      if ((h > theStoreOpenHour || (h === theStoreOpenHour && m >= theStoreOpenMinute)) && (h < theStoreCloseHour || (h === theStoreCloseHour && m < theStoreCloseMinute))) {
        this.timeSlots.push(
          {
            id: `${sd}T${this.getTimeSlotStr(h, m)}:00`,
            status: 0
          }
        );
      }
    //}
  }

  private getTimeSlotStr(h: number, m: number) {
    if (h < 10 && m === 0) {
      return `0${h}:0${m}`;
    } else if (h < 10) {
      return `0${h}:${m}`
    } else if (m === 0) {
      return `${h}:0${m}`;
    } else {
      return `${h}:${m}`;
    }
  }

  /*private generateDateItems(d: any) {
    for (let h = this.storeOpenHour; h <= this.storeCloseHour; h++) {
      for (let m = 0; m < 60; m = m+15) {
        if (h === this.storeOpenHour) {
          if (m >= this.storeOpenMinute) {
            this.pushDateItem(d, h, m);
          }
        } else if (h < this.storeCloseHour) {
          this.pushDateItem(d, h, m);
        } else if (m < this.storeCloseMinute) {
          this.pushDateItem(d, h, m);
        }
      }
    }
  }*/

  private generateDayItems(d: any) {
    for (let h = this.storeOpenHour; h <= this.storeCloseHour; h++) {
      for (let m = 0; m < 60; m = m+15) {
        if (h === this.storeOpenHour) {
          if (m >= this.storeOpenMinute) {
            this.pushDayItem(d, h, m);
          }
        } else if (h < this.storeCloseHour) {
          this.pushDayItem(d, h, m);
        } else if (m < this.storeCloseMinute) {
          this.pushDayItem(d, h, m);
        }
      }
    }
  }

  /*private pushDateItem(sd: any, h: number, m: number) {
    const ts = this.timeSlots.find((it: any) => it.id == `${moment(sd).format('YYYY-MM-DD')}T${this.getTimeSlotStr(h, m)}:00`);
    if (sd.getDay() === 1) { // Monday
      this.items.push(
        {
          timeSlot: this.getTimeSlotStr(h, m),
          monday: ts ? ts.status : -1,
          mondayDate: ts ? `${moment(sd).format('YYYY-MM-DD')}` : '',
          tuesday: 0,
          tuesdayDate: '',
          wednesday: 0,
          wednesdayDate: '',
          thursday: 0,
          thursdayDate: '',
          friday: 0,
          fridayDate: '',
          saturday: 0,
          saturdayDate: '',
          sunday: 0,
          sundayDate: ''
        }
      );
    } else {
      (this.items.find((it: any) => it.timeSlot === this.getTimeSlotStr(h, m)))[`${CODINGCONSTANTS.DAYS_OF_WEEK_SUNDAY_TO_STAURDAY[sd.getDay()]}`] = ts ? ts.status : -1;
      (this.items.find((it: any) => it.timeSlot === this.getTimeSlotStr(h, m)))[`${CODINGCONSTANTS.DAYS_OF_WEEK_SUNDAY_TO_STAURDAY[sd.getDay()]}Date`] = ts ? `${moment(sd).format('YYYY-MM-DD')}` : '';
    }
  }*/

  private pushDayItem(sd: any, h: number, m: number) {
    const ts = this.timeSlots.find((it: any) => it.id == `${sd}T${this.getTimeSlotStr(h, m)}:00`);
    if (sd === CODINGCONSTANTS.DAYS_OF_WEEK_MONDAY_TO_SUNDAY[0]) { // Monday
      this.items.push(
        {
          timeSlot: this.getTimeSlotStr(h, m),
          monday: ts ? ts.status : -1,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
          sunday: 0
        }
      );
    } else {
      (this.items.find((it: any) => it.timeSlot === this.getTimeSlotStr(h, m)))[`${sd}`] = ts ? ts.status : -1;
    }
  }

  onClickTimeSlot(item: any, day: string) {
    if (!this.isViewMode && (item[`${day}`] === 0 || item[`${day}`] === 1)) {
      let status = (item[`${day}`] === 0) ? 1 : 0;
      this.items.find((it: any) => it.timeSlot == item.timeSlot)[`${day}`] = status;
  
      if (this.resourceForm.scheduleType) {
        this.timeSlots.find((it: any) => it.id == `${item[`${day}Date`]}T${item.timeSlot}:00`).status = status;
        localStorage.setItem(`oneOffItems-page${this.currentPage}`, JSON.stringify(this.items));
        if (status) {
          if (this.resourceForm.openingSlots.find((it: any) => it.id == item[`${day}Date`])) {
            this.resourceForm.openingSlots.find((it: any) => it.id == item[`${day}Date`]).ts.push(`${item.timeSlot}:00`);
          } else {
            let oTSs: string[] = [];
            oTSs.push(`${item.timeSlot}:00`);
            this.resourceForm.openingSlots.push(
              {
                id: item[`${day}Date`],
                ts: oTSs 
              }
            );
          }
        } else {
          this.resourceForm.openingSlots.find((it: any) => it.id == item[`${day}Date`]).ts.filter((i: any) => i as string != `${item.timeSlot}:00`);
        }
      } else {
        this.timeSlots.find((it: any) => it.id == `${day}T${item.timeSlot}:00`).status = status;
        localStorage.setItem('recurringItems', JSON.stringify(this.items));
        if (status) {
          if (this.resourceForm.openingSlots.find((it: any) => it.id == day)) {
            this.resourceForm.openingSlots.find((it: any) => it.id == day).ts.push(`${item.timeSlot}:00`);
          } else {
            let oTSs: string[] = [];
            oTSs.push(`${item.timeSlot}:00`);
            this.resourceForm.openingSlots.push(
              {
                id: day,
                ts: oTSs 
              }
            );
          }
        } else {
          this.resourceForm.openingSlots.find((it: any) => it.id == day).ts = this.resourceForm.openingSlots.find((it: any) => it.id == day).ts.filter((i: any) => i as string != `${item.timeSlot}:00`);
        }
      }
    }
  }

  private getResourceById(id: number) {
    this.isLoading = true;
    this.resourceService.getResourceById(id).subscribe(
      res => {
        this.setUpForm(res);
        if (this.resourceForm.activityNameIds.length) {
          this.fetchActivities();
        }
        this.isLoading = false;
      },
      err => {
        Bugsnag.notify(err);
        this.isLoading = false;
      }
    );
  }

  private setUpForm(res: any) {
    this.resourceForm = Object.assign({}, res);
    this.orgResourceForm = Object.assign({}, this.resourceForm);
    this.orgResourceForm.openingSlots = Object.assign({}, this.resourceForm.openingSlots);
    this.selectedActivityGroups = this.activityGroupDropdownList.filter((it: any) => this.resourceForm.activityGroupIds.includes(it.id as number));
    this.selectedActivityNames = this.activityNameDropdownList.filter((it: any) => this.resourceForm.activityNameIds.includes(it.id as number));
    this.generateWeekView(this.currentPage);
    this.initializeCalendarOptions();
    this.prePopulateTimeSlots();
    this.limit = this.items.length;
    this.totalItems = this.totalPages * this.limit;

    if (null != this.resourceForm.timeOffSlots && this.resourceForm.timeOffSlots.length) {
      this.fillTimeOff();
    }
  }

  onSelectCountry(event: any) {
    let value: string = '';
    if (typeof event === 'string' || event instanceof String) {
      value = event as string;
    } else {
      value = (event.target as HTMLInputElement).value;
    }

    if (value && value.trim()) {
      this.isLoading = true;
      this.storeService.getStoresByCountry(value).subscribe(
        res => {
          this.stores = res;
       },
        err => {
          Bugsnag.notify(err);
       }
      );
      this.isFormValid = true;
      this.clearError('country');
      this.isLoading = false;
    } else {
      this.resourceForm.storeCode = '';
      this.isFormValid = false;
      this.showError('country', CONSTANTS.SELECT_COUNTRY);
    }
  }

  onSelectStore(event: any) {
    this.resourceForm.storeCode = (event.target as HTMLInputElement).value as string;
    if (this.resourceForm.storeCode && this.stores.filter((it: any) => [this.resourceForm.storeCode].includes(it.storeCode as string)).length > 0) {
      this.fetchStoreProperties(this.resourceForm.storeCode, 0);
      this.isFormValid = true;
      this.clearError('storeCode');
    } else {
      this.resourceForm.storeCode = '';
      this.isFormValid = false;
      this.showError('storeCode', CONSTANTS.SELECT_STORE);
    }
  }

  changeTimeOff() {
    if (!this.resourceForm.timeOff) {
      if($('div[id^="add-time-off-"]').length > 1) {
        $('div[id^="add-time-off-"]:last').remove();
      }
      this.tempTimeOffSlots = 0;
      if(this.resourceForm.timeOffSlots.length > 1){
        if(this.resourceForm.deletedSlots == null){
          this.resourceForm.deletedSlots = [];
        }
        for (const tos of this.resourceForm.timeOffSlots) {
          this.resourceForm.deletedSlots.push(tos.scheduleId);
          this.resourceForm.timeOffSlots = [];
        }
      }
    }
  }

  changeOneOff() {
    if (this.resourceForm.scheduleType) {
      this.resourceForm.scheduleType = 0; // is recurring
    } else {
      if($('div[id^="add-time-off-"]').length > 1) {
        $('div[id^="add-time-off-"]:last').remove();
      }
      this.resourceForm.scheduleType = 1; //one-off
    }
    this.tempTimeOffSlots = 0;
    if (this.resourceForm.fromDate && this.resourceForm.toDate) {
      this.onDateChangeTo();
      this.onDateChangeFrom();
    }
    this.initializeCalendarOptions();
  }

  addTimeOff() {
    if (this.resourceForm.timeOffSlots.length < 10) {
      const $templateDiv = $('div[id^="add-time-off-"]:first');
      const $lastDiv = $('div[id^="add-time-off-"]:last');
      const num = parseInt($lastDiv.prop("id").match(/\d+/g), 10) + 1;
      const $cloneDiv = $templateDiv.clone().prop('id', 'add-time-off-'+num);
      $cloneDiv.find('[name="lbl-days-add-time-off"]').text($cloneDiv.find('[name="lbl-days-add-time-off"]').text().replace('0', '' + num));
      $cloneDiv.find('#error-of-add-time-off-0').prop('id', 'error-of-add-time-off-'+num).attr('for', 'add-time-off-'+num);
      $cloneDiv.find('#from-date-add-time-off-0').prop('id', 'from-date-add-time-off-'+num).val(this.resourceForm.fromDate);
      $cloneDiv.find('#to-date-add-time-off-0').prop('id', 'to-date-add-time-off-'+num).val(this.resourceForm.fromDate);
      $cloneDiv.find('#scheduleId-0').prop('id', 'scheduleId-'+num);
      $cloneDiv.find('#hour-from-add-time-off-0').prop('id', 'hour-from-add-time-off-'+num).attr('min', this.storeOpenHour).attr('max', this.storeCloseHour).val(this.storeOpenHour).change((e: any) => {
        this.correctInputHours(e.target.id);
      });
      $cloneDiv.find('#from-minute-add-time-off-0').prop('id', 'from-minute-add-time-off-'+num);
      $cloneDiv.find('#hour-to-add-time-off-0').prop('id', 'hour-to-add-time-off-'+num).attr('min', this.storeOpenHour).attr('max', this.storeCloseHour).val(this.storeCloseHour).change((e: any) => {
        this.correctInputHours(e.target.id);
      });
      $cloneDiv.find('#to-minute-add-time-off-0').prop('id', 'to-minute-add-time-off-'+num).val(this.storeCloseMinute);

      $cloneDiv.find('#add-button-add-time-off-0').prop('id', 'add-button-add-time-off-'+num).click((e: any) => {
        e.preventDefault();
        const from = this.convertToUTC($cloneDiv.find('#from-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),  Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()), this.storeTimeZone);
        const to = this.convertToUTC($cloneDiv.find('#to-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()), Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()), this.storeTimeZone); 
        const storeFromDate = this.convertTimeSelectedWithTime($cloneDiv.find('#from-date-add-time-off-'+num).val(), "00:00:00Z");
        const storeToDate =  this.convertTimeSelectedWithTime($cloneDiv.find('#to-date-add-time-off-'+num).val(), "23:59:59Z");
        const o = {
          num: num,
          fromDate: $cloneDiv.find('#from-date-add-time-off-'+num).val(),
          toDate: $cloneDiv.find('#to-date-add-time-off-'+num).val(),
          fromHour: Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),
          fromMinute: Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()),
          toHour: Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()),
          toMinute: Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()),
          utcFromHrMin: from.utcTime,
          utcToHrMin: to.utcTime,
          storeFromDate: storeFromDate,
          storeToDate: storeToDate,
          day: from.day
        }
        const vr: number = this.validateTimeOff(o, -1);
        if (vr === 0) {
          this.resourceForm.timeOffSlots.push(o);
          this.timeOffSlots = [];
          for (const tos of this.resourceForm.timeOffSlots) {
            this.fillTimeOffSlots(tos);
          }
          this.tempTimeOffSlots--;
          $cloneDiv.find('#from-date-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#to-date-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#hour-from-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#from-minute-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#hour-to-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#to-minute-add-time-off-'+num).attr('disabled', 'disabled');
          $cloneDiv.find('#add-button-add-time-off-'+num).hide();
          $cloneDiv.find('#edit-button-add-time-off-'+num).show();
          this.isFormValid = true;
          this.clearError('add-time-off-'+num);
        } else {
          this.isFormValid = false;
          this.showErrorOfTimeOffSlots(vr, 'add-time-off-'+num);
        }
        $cloneDiv.find('#remove-button-add-time-off-'+num).show();
      });

      $cloneDiv.find('#edit-button-add-time-off-0').prop('id', 'edit-button-add-time-off-'+num).hide().click((e: any) => {
        e.preventDefault();
        if($cloneDiv.find('#edit-button-add-time-off-'+num).text() === 'Edit') {
          $cloneDiv.find('#edit-button-add-time-off-'+num).text('Save');
          this.resourceForm.timeOffSlots = this.resourceForm.timeOffSlots.filter(tos => tos.num !== num);
          this.timeOffSlots = [];
          for (const tos of this.resourceForm.timeOffSlots) {
            this.fillTimeOffSlots(tos);
          }
          this.tempTimeOffSlots++;
          $cloneDiv.find('#from-date-add-time-off-'+num).removeAttr('disabled');
          $cloneDiv.find('#to-date-add-time-off-'+num).removeAttr('disabled');
          $cloneDiv.find('#hour-from-add-time-off-'+num).removeAttr('disabled');
          $cloneDiv.find('#from-minute-add-time-off-'+num).removeAttr('disabled');
          $cloneDiv.find('#hour-to-add-time-off-'+num).removeAttr('disabled');
          $cloneDiv.find('#to-minute-add-time-off-'+num).removeAttr('disabled');
        } else {
          $cloneDiv.find('#edit-button-add-time-off-'+num).text('Edit');
          const from = this.convertToUTC($cloneDiv.find('#from-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),  Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()), this.storeTimeZone);
          const to = this.convertToUTC($cloneDiv.find('#to-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()), Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()), this.storeTimeZone); 
          const storeFromDate = this.convertToLocalTime($cloneDiv.find('#from-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),  Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()), this.storeTimeZone);
          const storeToDate =  this.convertToLocalTime($cloneDiv.find('#to-date-add-time-off-'+num).val(), Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()), Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()), this.storeTimeZone);
  
          const o = {
            num: num,
            fromDate: $cloneDiv.find('#from-date-add-time-off-'+num).val(),
            toDate: $cloneDiv.find('#to-date-add-time-off-'+num).val(),
            fromHour: Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),
            fromMinute: Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()),
            toHour: Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()),
            toMinute: Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()),
            utcFromHrMin: from.utcTime,
            utcToHrMin: to.utcTime,
            storeFromDate: storeFromDate,
            storeToDate: storeToDate,
            day: from.day
          }
          const vr: number = this.validateTimeOff(o, -1);
          if (vr === 0) {
            console.log(o);
            this.resourceForm.timeOffSlots.push(o);
            this.timeOffSlots = [];
            for (const tos of this.resourceForm.timeOffSlots) {
              this.fillTimeOffSlots(tos);
            }
            this.tempTimeOffSlots--;
            $cloneDiv.find('#from-date-add-time-off-'+num).attr('disabled', 'disabled');
            $cloneDiv.find('#to-date-add-time-off-'+num).attr('disabled', 'disabled');
            $cloneDiv.find('#hour-from-add-time-off-'+num).attr('disabled', 'disabled');
            $cloneDiv.find('#from-minute-add-time-off-'+num).attr('disabled', 'disabled');
            $cloneDiv.find('#hour-to-add-time-off-'+num).attr('disabled', 'disabled');
            $cloneDiv.find('#to-minute-add-time-off-'+num).attr('disabled', 'disabled');
            this.isFormValid = true;
            this.clearError('add-time-off-'+num);
          } else {
            this.isFormValid = false;
            this.showErrorOfTimeOffSlots(vr, 'add-time-off-'+num);
          }
        };
      });

      $cloneDiv.find('#remove-button-add-time-off-0').prop('id', 'remove-button-add-time-off-'+num).click((e: any) => {
        e.preventDefault();
        if ($('#add-button-add-time-off-'+num).is(":hidden")) {
          const o = {
            fromDate: $cloneDiv.find('#from-date-add-time-off-'+num).val(),
            toDate: $cloneDiv.find('#to-date-add-time-off-'+num).val(),
            fromHour: Number($cloneDiv.find('#hour-from-add-time-off-'+num).val()),
            fromMinute: Number($cloneDiv.find('#from-minute-add-time-off-'+num).val()),
            toHour: Number($cloneDiv.find('#hour-to-add-time-off-'+num).val()),
            toMinute: Number($cloneDiv.find('#to-minute-add-time-off-'+num).val()),
          }
          if(this.resourceForm.deletedSlots == null){
            this.resourceForm.deletedSlots = [];
          }
          this.resourceForm.timeOffSlots.forEach(timeoff => {
            if ((timeoff.scheduleId === Number($cloneDiv.find('#scheduleId-'+num).val()) ) && (timeoff.duplicateSchedules != null && timeoff.duplicateSchedules.length > 0)) {
              this.resourceForm.deletedSlots.push(...timeoff.duplicateSchedules);
            }
          });
          this.resourceForm.deletedSlots.push(Number($cloneDiv.find('#scheduleId-'+num).val())); // 

          this.resourceForm.timeOffSlots = this.resourceForm.timeOffSlots.filter(tos => tos.num !== num);
          this.timeOffSlots = [];
          for (const tos of this.resourceForm.timeOffSlots) {
            this.fillTimeOffSlots(tos);
          }
        } else {
          this.tempTimeOffSlots--;
          this.isFormValid = true;
        }
        $('#add-time-off-'+num).remove();
      });

      $lastDiv.after($cloneDiv.show());
      this.tempTimeOffSlots++;
    }
  }
  private convertToLocalTime(date: string, hour: number, minute: number, timezone: string) {
    return moment.tz(`${date}T${hour}:${minute}:00`, "YYYY-MM-DDTHH:mm:ss", timezone);
  } 
  private convertToUTC(date: string, hour: number, minute: number, timezone: string) {
    const dateTime = moment.tz(`${date}T${hour}:${minute}:00`, "YYYY-MM-DDTHH:mm:ss", timezone);
    const utcDateTime = dateTime.utc();
    // Format the time as hh:mm:ss
    const utcTime = utcDateTime.format("HH:mm");

    return {
        utcDate: utcDateTime.format("YYYY-MM-DDTHH:mm:ss") + "Z",
        utcTime: utcTime + "Z",
        day: utcDateTime.day(),
    };
  }

  private fillTimeOffSlots(o: any) {
    const fd = new Date(o.fromDate);
    const td = new Date(o.toDate);
    for (let sd = fd; sd <= td; sd.setDate(sd.getDate() + 1)) {
      for (let h = o.fromHour; h <= o.toHour; h++) {
        for (let m = 0; m < 60; m = m+15) {
          if (h === o.fromHour) {
            if (m >= o.fromMinute) {
              this.pushATimeOffSlot(sd, h, m);
            }
          } else if (h < o.toHour) {
            this.pushATimeOffSlot(sd, h, m);
          } else if (m < o.toMinute) {
            this.pushATimeOffSlot(sd, h, m);
          }
        }
      }
    }
  }

  private pushATimeOffSlot(sd: any, h: number, m: number) {
    this.timeOffSlots.push(
      {
        id: `${moment(sd).format('YYYY-MM-DD')}T${this.getTimeSlotStr(h, m)}:00`,
        status: -1
      }
    );
  }

  private fillTimeOff() {
    let num = 0;
    this.timeOffSlots = [];

    for (const tos of this.resourceForm.timeOffSlots) {
      let currentDateTime = moment().tz(this.storeTimeZone);
      // let fromDateTime =  moment().tz(tos.storeFromDate).tz(this.storeTimeZone);
      // let toDateTime = moment().tz(tos.storeToDate).tz(this.storeTimeZone);
      let storeFromDate = moment.tz(tos.storeFromDate, this.storeTimeZone).format("YYYY-MM-DD");
      let storeToDate = moment.tz(tos.storeToDate, this.storeTimeZone).format("YYYY-MM-DD");
      let localFromTime = this.convertToTimeZone(tos.utcFromHrMin, this.storeTimeZone);
      let localToTime = this.convertToTimeZone(tos.utcToHrMin, this.storeTimeZone);
      // Combine the extracted date with utcFromHrMin and utcToHrMin
      let utcFromDateTime = moment.tz(`${storeFromDate}T${localFromTime}:00`, "YYYY-MM-DDTHH:mm:ss", this.storeTimeZone);
      let utcToDateTime = moment.tz(`${storeToDate}T${localToTime}:00`, "YYYY-MM-DDTHH:mm:ss", this.storeTimeZone);
      
      // Convert UTC times to the store's timezone
      let fromDateTime = utcFromDateTime.clone().tz(this.storeTimeZone);
      let toDateTime = utcToDateTime.clone().tz(this.storeTimeZone);

      // Ignore time and only compare dates
      const isSameOrBeforeToday = currentDateTime.clone().startOf('day').isSameOrBefore(toDateTime.clone().startOf('day'));

      // Ensure ToDateTime is not in the past (ignoring time)
      if (isSameOrBeforeToday) {
        num++;
        this.addTimeOff();
        if (this.tempTimeOffSlots > 0) {
          this.tempTimeOffSlots--;
        }
        tos.num = num;
      
        
             // Extract the date and time components
        tos.fromDate = fromDateTime.format('YYYY-MM-DD');
        tos.toDate = toDateTime.format('YYYY-MM-DD');
        tos.fromHour = fromDateTime.hour();
        tos.toHour = toDateTime.hour();
        tos.fromMinute = fromDateTime.minute();
        tos.toMinute = toDateTime.minute();
        $('#from-date-add-time-off-'+num).val(tos.fromDate);
        $('#to-date-add-time-off-'+num).val(tos.toDate);
        $('#hour-from-add-time-off-'+num).val(tos.fromHour);
        $('#hour-to-add-time-off-'+num).val(tos.toHour);
        $('#from-minute-add-time-off-'+num).val(tos.fromMinute);
        $('#to-minute-add-time-off-'+num).val(tos.toMinute);
        $('#scheduleId-'+num).val(tos.scheduleId);
        $('#from-date-add-time-off-'+num).attr('disabled', 'disabled');
        $('#to-date-add-time-off-'+num).attr('disabled', 'disabled');
        $('#hour-from-add-time-off-'+num).attr('disabled', 'disabled');
        $('#hour-to-add-time-off-'+num).attr('disabled', 'disabled');
        $('#from-minute-add-time-off-'+num).attr('disabled', 'disabled');
        $('#to-minute-add-time-off-'+num).attr('disabled', 'disabled');
        $('#add-button-add-time-off-'+num).attr('disabled', 'disabled').hide();
        if (this.isViewMode) {
          $('#remove-button-add-time-off-'+num).attr('disabled', 'disabled').hide();
        }
        this.fillTimeOffSlots(tos);
      }else{
        console.log("delete the past time-off slots: "+toDateTime);
        if(this.resourceForm.deletedSlots == null){
          this.resourceForm.deletedSlots = [];
        }
        this.resourceForm.deletedSlots.push(tos.scheduleId );
      }
    }
  }

  private correctInputHours(id: string) {
    const min = parseInt($('#'+id).attr('min'));
    const max = parseInt($('#'+id).attr('max'));
    if ($('#'+id).val() > max) {
      $('#'+id).val(max);
    } else if ($('#'+id).val() < min) {
      $('#'+id).val(min);
    }
  }

  private validateTimeOff(o: any, status: number) {
    if (!o.fromDate || new Date(o.fromDate) < new Date(this.resourceForm.fromDate) || o.fromHour < this.storeOpenHour || (o.fromHour === this.storeOpenHour && o.fromMinute < this.storeOpenMinute)) {
      return 2; // Before the schedule start
    } else if (!o.toDate || new Date(o.toDate) > new Date(this.resourceForm.toDate) || o.toHour > this.storeCloseHour || (o.toHour === this.storeCloseHour && o.toMinute > this.storeCloseMinute)) {
      return 3; // After the schedule end
    }else if(o.toHour < o.fromHour){
      return 5;
    }else{
      // const fromDate = moment.tz(o.fromDate, 'YYYY-MM-DD', this.storeTimeZone);

      // // const fromDate = new Date(this.resourceForm.fromDate);
      // const dayOfWeek = fromDate.day();
      // const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      // const selectedTimeOffDay = daysOfWeek[dayOfWeek];
      // console.log(this.selectedSlots); //fill in logic here
      // const fromTime = new Date(`${o.fromDate}T${o.fromHour.toString().padStart(2, '0')}:${o.fromMinute.toString().padStart(2, '0')}`);
      // const toTime = new Date(`${o.toDate}T${o.toHour.toString().padStart(2, '0')}:${o.toMinute.toString().padStart(2, '0')}`);
      
      // const fromTimeString = `${o.fromHour.toString().padStart(2, '0')}:${o.fromMinute.toString().padStart(2, '0')}`;
      // const toTimeString = `${o.toHour.toString().padStart(2, '0')}:${o.toMinute.toString().padStart(2, '0')}`;

      // for (let slot of this.selectedSlots) {
      //   const slotStartTime =  slot.startDateTime != undefined ?  slot.startDateTime :  this.convertUtcToTimeZoneFormat(slot.start);
      //   const slotEndTime = slot.endDateTime != undefined ? slot.endDateTime : this.convertUtcToTimeZoneFormat(slot.end);
      //   const slotSelectedDay =    daysOfWeek[moment.tz(slotStartTime.split('T')[0], 'YYYY-MM-DD', this.storeTimeZone).day()];
      //   if(selectedTimeOffDay == slotSelectedDay){
      //     const slotStartTimeString = slotStartTime.split('T')[1].slice(0, 5);
      //     const slotEndTimeString = slotEndTime.split('T')[1].slice(0, 5);
  
      //     if ((fromTimeString >= slotStartTimeString && fromTimeString < slotEndTimeString) || 
      //         (toTimeString > slotStartTimeString && toTimeString <= slotEndTimeString) || 
      //         (fromTimeString <= slotStartTimeString && toTimeString >= slotEndTimeString)) {
      //       return 6; // Overlaps with an existing slot
      //     }
      //   }
      // }
      // return 0;
    }

    if (this.resourceForm.timeOffSlots.length > 0) {
      const vr: number = this.validateToAddTimeOff(this.timeOffSlots, o, status);
      if (vr > 0) {
        return vr;
      }
    }
    this.fillTimeOffSlots(o);
    return 0;
  }

  private validateToAddTimeOff(timeOffSlots: any[], o: any, status: number) {
    for (let i of timeOffSlots) {
      if (new Date(i.id).setHours(0,0,0,0) >= new Date(o.fromDate).setHours(0,0,0,0) && new Date(i.id).setHours(0,0,0,0) <= new Date(o.toDate).setHours(0,0,0,0)) {
        if (Number(i.id.split('T')[1].split(':')[0]) === o.fromHour) {
          if (o.fromHour === o.toHour) {
            if (Number(i.id.split('T')[1].split(':')[1]) >= o.fromMinute && Number(i.id.split('T')[1].split(':')[1]) <= o.toMinute) {
              const vr: number = this.validateATimeOffSlot(i, status);
              if (vr > 0) {
                return vr;
              }
            }
          } else if (Number(i.id.split('T')[1].split(':')[1]) >= o.fromMinute) {
            const vr: number = this.validateATimeOffSlot(i, status);
            if (vr > 0) {
              return vr;
            }
          }
        } else if (Number(i.id.split('T')[1].split(':')[0]) > o.fromHour && Number(i.id.split('T')[1].split(':')[0]) < o.toHour) {
            const vr: number = this.validateATimeOffSlot(i, status);
            if (vr > 0) {
              return vr;
            }
        } else if (Number(i.id.split('T')[1].split(':')[0]) === o.toHour) {
          if (o.toHour === o.fromHour) {
            if (Number(i.id.split('T')[1].split(':')[1]) <= o.toMinute && Number(i.id.split('T')[1].split(':')[1]) >= o.fromMinute) {
              const vr: number = this.validateATimeOffSlot(i, status);
              if (vr > 0) {
                return vr;
              }
            }
          } else if (Number(i.id.split('T')[1].split(':')[1]) <= o.toMinute) {
            const vr: number = this.validateATimeOffSlot(i, status);
            if (vr > 0) {
              return vr;
            }
          }
        }
      }
    }
    return 0;
  }

  private validateATimeOffSlot(i: any, status: number) {
    if (i.status === -1 && status === -1) { // Set time off on time off
      return 1;
    }
    return 0; // Valid
  }

  private showErrorOfTimeOffSlots(vr: number, ide: string) {
    switch (vr) {
      case 1: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_1);
        break;
      }
      case 2: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_2);
        break;
      }
      case 3: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_3);
        break;
      }
      case 4: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_4);
        break;
      }
      case 5: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_5);
        break;
      }
      case 6: {
        this.showError(ide, CONSTANTS.ERROR_TIME_SLOTS_6);
        break;
      }
    }
  }

  clearErrorIfValidRequire(event: any) {
    const target = event.target as HTMLInputElement
    if (target) {
      const value = target.value;
      if (value && value.trim() && ![CONSTANTS.SELECT_COUNTRY, CONSTANTS.SELECT_STORE, CONSTANTS.SELECT_OPTION, CONSTANTS.SELECT_OPTIONS].includes(value)) {
        this.isFormValid = true;
        this.clearError(target.id);
      } else {
        this.isFormValid = false;
        this.showError(target.id, target.id !== 'name' ? CONSTANTS.ERROR_REQUIRE_SELECT : CONSTANTS.ERROR_REQUIRE_TEXT);
      }
    }
  }

  validateSelectedActivityGroups(event: any) {
    if (!this.isViewMode) {
      const target = event.target as HTMLInputElement
      if (target) {
        if (this.resourceForm.activityGroupIds.length) {
          this.isFormValid = true;
          $("div.text-error[for='activityGroupMultiSelect']").hide();
        } else {
          this.isFormValid = false;
          $("div.text-error[for='activityGroupMultiSelect']").html(this.multiselectPlaceHolder).show();
        }
      }
    }
  }

  validateSelectedActivityNames(event: any) {
    if (!this.isViewMode) {
      const target = event.target as HTMLInputElement;
      if (target) {
        if (this.resourceForm.activityNameIds.length) {
          this.isFormValid = true;
          $("div.text-error[for='activityNameMultiSelect']").hide();
        } else {
          this.isFormValid = false;
          $("div.text-error[for='activityNameMultiSelect']").html(this.multiselectPlaceHolder).show();
        }
      }
    }
  }

  submitForm() {
    if(this.resourceForm.openingSlots.length > 0) {
      this.isFormValid = true;
    }
    if (!this.isFormValid || !this.validateTempTimeOffs()) {
      return;
    } else if (this.validateForm()) {
      if (this.isEditMode && !this.checkChanges()) {
        this.showNothingChangeConfirmDialog();
      } else if (this.validateHasNotAnOpeningTimeSlots()) {
        this.showNotAnyOpeningTimeSlotsConfirmDialog();
      } else {
        if (!this.isEditMode) {
          this.createResource();
        } else {
          this.updateResource();
        }
      }
    }
  }

  private validateForm() {
    if (!this.country) {
      this.isFormValid = false;
      this.showError('country', CONSTANTS.SELECT_COUNTRY);
    } else if (!this.resourceForm.storeCode) {
      this.isFormValid = false;
      this.showError('storeCode', CONSTANTS.SELECT_STORE);
    } else if (!this.resourceForm.name || !this.resourceForm.name.trim()) {
      this.isFormValid = false;
      this.showError('name', CONSTANTS.ERROR_REQUIRE_TEXT);
    } else if (!this.resourceForm.activityGroupIds.length) {
      this.isFormValid = false;
      $("div.text-error[for='activityGroupMultiSelect']").html(this.multiselectPlaceHolder).show();
    } else if (!this.resourceForm.activityNameIds.length) {
      this.isFormValid = false;
      $("div.text-error[for='activityNameMultiSelect']").html(this.multiselectPlaceHolder).show();
    } else if (!this.resourceForm.fromDate) {
      this.isFormValid = false;
      this.showError('fromDate', CONSTANTS.ERROR_REQUIRE_TEXT);
    } else if (!this.resourceForm.toDate) {
      this.isFormValid = false;
      this.showError('toDate', CONSTANTS.ERROR_REQUIRE_TEXT);
    }  else if (this.resourceForm.openingSlots.length === 0) {
      this.isFormValid = false;
      this.showError('calendar', CONSTANTS.ERROR_REQUIRE_SLOTS);
    } else {
      this.isFormValid = true;
    }
    this.resourceForm.fromDate = this.convertTimeSelectedWithTime(this.resourceForm.fromDate, "00:00:00");
    this.resourceForm.toDate = this.convertTimeSelectedWithTime(this.resourceForm.toDate, "23:59:59");
    return this.isFormValid;
  }

  private validateHasNotAnOpeningTimeSlots() {
    let hasAnOpeningTimeSlot = false;
    if (this.selectedSlots.length === 0) {
      hasAnOpeningTimeSlot = true;
    }
    return hasAnOpeningTimeSlot;
  } 

  showNotAnyOpeningTimeSlotsConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.RESOURCE_PUBLISH_CONFIRM,
      CONSTANTS.RESOURCE_PUBLISH_CONFIRM_CONTENT,
      ICONS.CONFIRM,
      null,
      'Cancel',
      () => {
        this.messageModal.close();
        if (!this.isEditMode) {
          this.createResource();
        } else {
          this.updateResource();
        }
     },
      'OK'
    );
  }

  checkboxByBAName() {
    this.resourceForm.bookByBAName = !this.resourceForm.bookByBAName;
  }

  private validateTempTimeOffs() {
    if (this.resourceForm.scheduleType) {
      return true;
    }
    const tempTimeOffSlots = $('div[id^="add-time-off-"]');
    for (let i = 0; i < tempTimeOffSlots.length; i++) {
      const id = tempTimeOffSlots[i].id;
      if ((!$('#add-button-'+id).is(":hidden")) && (id !== 'add-time-off-0')) {
        this.showErrorOfTimeOffSlots(4, id);
      }
    }
    return this.tempTimeOffSlots == 0;
  } 

  private checkChanges() {
    let hasChanges = false;
    this.resourceForm.audits = [];
    if (this.orgResourceForm.storeCode !== this.resourceForm.storeCode) {
      this.pushAnAuditRecord('storeCode', this.orgResourceForm.storeCode, this.resourceForm.storeCode);
      hasChanges = true;
    }
    if (this.orgResourceForm.status !== this.resourceForm.status) {
      this.pushAnAuditRecord('status', this.orgResourceForm.status.toString(), this.resourceForm.status.toString());
      hasChanges = true;
    }
    if (this.orgResourceForm.name !== this.resourceForm.name) {
      this.pushAnAuditRecord('name', this.orgResourceForm.name, this.resourceForm.name);
      hasChanges = true;
    }
    if (this.orgResourceForm.expertise.map((e: any) => {return e.id}).toString() !== this.resourceForm.expertise.map((e: any) => {return e.id}).toString()) {
      this.pushAnAuditRecord('expertise', this.orgResourceForm.expertise.map((e: any) => {return e.id}).toString(), this.resourceForm.expertise.map((e: any) => {return e.id}).toString());
      hasChanges = true;
    }
    if (this.orgResourceForm.activityGroupIds.toString() !== this.resourceForm.activityGroupIds.toString()) {
      this.pushAnAuditRecord('activityGroupIds', this.orgResourceForm.activityGroupIds.toString(), this.resourceForm.activityGroupIds.toString());
      hasChanges = true;
    }
    if (this.orgResourceForm.activityNameIds.toString() !== this.resourceForm.activityNameIds.toString()) {
      this.pushAnAuditRecord('activityNameIds', this.orgResourceForm.activityNameIds.toString(), this.resourceForm.activityNameIds.toString());
      hasChanges = true;
    }
    if (this.orgResourceForm.scheduleType !== this.resourceForm.scheduleType) {
      this.pushAnAuditRecord('scheduleType', this.orgResourceForm.scheduleType.toString(), this.resourceForm.scheduleType.toString());
      hasChanges = true;
    }
    if (this.orgResourceForm.fromDate !== this.resourceForm.fromDate) {
      this.pushAnAuditRecord('fromDate', this.orgResourceForm.fromDate, this.resourceForm.fromDate);
      hasChanges = true;
    }
    if (this.orgResourceForm.toDate !== this.resourceForm.toDate) {
      this.pushAnAuditRecord('toDate', this.orgResourceForm.toDate, this.resourceForm.toDate);
      hasChanges = true;
    }
    if (this.orgResourceForm.openingSlots.length !== this.resourceForm.openingSlots.length) {
      //CloudWatch Logs:
      //this.pushAnAuditRecord('openingSlots', JSON.stringify(this.orgResourceForm.openingSlots), JSON.stringify(this.resourceForm.openingSlots));
      hasChanges = true;
    } else {
      const oLength = this.orgResourceForm.openingSlots.length;
      const o = this.orgResourceForm.openingSlots;
      const n = this.resourceForm.openingSlots;
      let of = false;
      for (let i = 0; i < oLength; i++) {
        for (const [key, value] of Object.entries(this.orgResourceForm.openingSlots[0])) {
          if (key != 'id' && o[i][`${key}`] !== n[i][`${key}`]) {
            //CloudWatch Logs:
            //this.pushAnAuditRecord('openingSlots', JSON.stringify(this.orgResourceForm.openingSlots), JSON.stringify(this.resourceForm.openingSlots));
            hasChanges = true;
            of = true;
            break;
          }
        }
        if (of) break;
      }
    }

    // if (this.orgResourceForm.timeOffSlots.length !== this.resourceForm.timeOffSlots.length) {
    //   this.pushAnAuditRecord('timeOffSlots', JSON.stringify(this.orgResourceForm.timeOffSlots), JSON.stringify(this.resourceForm.timeOffSlots));
    //   hasChanges = true;
    // } else {
    //   const tLength = this.orgResourceForm.timeOffSlots.length;
    //   const ot = this.orgResourceForm.timeOffSlots;
    //   const nt = this.resourceForm.timeOffSlots;
    //   let tf = false;
    //   for (let i = 0; i < tLength; i++) {
    //     for (const [key, value] of Object.entries(this.orgResourceForm.timeOffSlots[0])) {
    //       if (key != 'id' && ot[i][`${key}`] !== nt[i][`${key}`]) {
    //         this.pushAnAuditRecord('timeOffSlots', JSON.stringify(this.orgResourceForm.timeOffSlots), JSON.stringify(this.resourceForm.timeOffSlots));
    //         hasChanges = true;
    //         tf = true;
    //         break;
    //       }
    //     }
    //     if (tf) break;
    //   }
    // }
    if(this.orgResourceForm.bookByBAName !== this.resourceForm.bookByBAName) {
      this.pushAnAuditRecord('bookByBAName', this.orgResourceForm.bookByBAName.toString(), this.resourceForm.bookByBAName.toString());
      hasChanges = true;
    }

    return hasChanges;
  }

  private pushAnAuditRecord(fn: string, ov: string, nv: string) {
    this.resourceForm.audits.push({
      resourceId: this.resourceForm.id,
      fieldName: fn,
      oldValue: ov,
      newValue: nv,
      action: 'update',
      actionById: 0,
      actionAt: null
    });
  }

  private showNothingChangeConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.RESOURCE_PUBLISH_CONFIRM,
      CONSTANTS.RESOURCE_PUBLISH_CONFIRM_NOTHING_CHANGES_FOUND,
      ICONS.CONFIRM,
      null,
      'Close'
    );
  }

  private createResource() {
    this.isLoading = true;
    this.resourceService.create(this.resourceForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          res.body.msg,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        this.backToResourceList();
     },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          err.error.msg,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        Bugsnag.notify(err);
        this.backToResourceList();
     }
    );
    
  }

  updateResource() {
    this.isLoading = true;
    this.resourceService.update(this.resourceForm).subscribe(
      res => {
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          res.body.msg,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        this.backToResourceList();
      },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          err.error.msg,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        Bugsnag.notify(err);
        this.backToResourceList();
      }
    );
   
  }

  showDeleteConfirmDialog() {
    this.messageModal.show(
      CONSTANTS.RESOURCE_DELETE_CONFIRM,
      CONSTANTS.RESOURCE_DELETE_CONFIRM_CONTENT,
      ICONS.CONFIRM,
      null,
      'Cancel',
      () => {
        this.messageModal.close();
        this.deleteResource();
     },
      'OK'
    );
  }

  deleteResource() {
    this.isLoading = true;
    this.resourceService.delete(this.resourceForm.storeCode, this.resourceForm.id).subscribe(
      res => {
        this.showSuccessPopup(res.body.msg);
        this.isLoading = false;
        this.backToResourceList();
      },
      err => {
        this.showErrorPopup(err.error.msg);
        this.isLoading = false;
        Bugsnag.notify(err);
        this.backToResourceList();
      }
    );
  }

  cancelForm() {
    this.backToResourceList();
  }

  backToResourceList() {
    this.router.navigate(['/management'], {queryParams: {t: 'resource'}});
  }

  showSuccessPopup(message: string) {
    this.notificationsService.success(
      CONSTANTS.SUCCESS,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      }
    );
  }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
  }

  private clearError(inputId: string) {
    $("div.text-error[for='" + inputId + "']").hide();
  }

  private showError(inputId: string, message: string) {
    const errorElement = $("div.text-error[for='" + inputId + "']");
    errorElement.html(message).show();
  
    // Scroll to the error element
    if (errorElement.length) {
      $('html, body').animate({
        scrollTop: errorElement.offset().top - 100 
      }, 500); 
    }
  }

  onExpertiseSelect(item: any) {}

  onExpertiseDeSelect(item: any) {}

  onExpertiseSelectAll(items: any) {}

  onActivityGroupSelect(item: any) {
    this.resourceForm.activityGroupIds.push(item.id);
    this.fetchActivities();
  }

  onActivityGroupDeSelect(item: any) {
    this.resourceForm.activityGroupIds = this.resourceForm.activityGroupIds.filter(i => i != item.id);
    this.fetchActivities();
  }

  onActivityGroupSelectAll(items: any) {
    this.resourceForm.activityGroupIds = this.activityGroupDropdownList.map((e: any) => {return e.id});
    this.fetchActivities();
  }

  onActivityGroupDeselectAll(items: any) {
    this.resourceForm.activityGroupIds = [];
    this.activityNameDropdownList = [];
    this.selectedActivityNames = [];
    this.resourceForm.activityNameIds = [];
    this.fetchActivities();
  }

  onActivityNameSelect(item: any) {
    this.resourceForm.activityNameIds.push(item.id);
  }

  onActivityNameDeSelect(item: any) {
    this.resourceForm.activityNameIds = this.resourceForm.activityNameIds.filter(i => i != item.id);
  }

  onActivityNameSelectAll(items: any) {
    this.resourceForm.activityNameIds = this.activityNameDropdownList.map((e: any) => {return e.id});
  }
  convertToUTCDate(date: string): string {
    const timeZone = this.storeTimeZone;
    // Convert from Asia/Singapore to UTC
    return moment.tz(date, timeZone).utc().format().toString();
  }
  convertTimeSelectedWithTime(date: string, time: string): string {
    // Combine the date and manually passed time, then format it
    const cleanTime = time.replace("Z", "");

    const datePart = moment.tz(date, this.storeTimeZone).format('YYYY-MM-DD');
    return  moment.tz(`${datePart}T${cleanTime}`, this.storeTimeZone).format().toString();
  }
  convertTimeSelected(dateTime: string): string {
    return moment(dateTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
  }  
  
   convertUTCToTimeZone(date: string, timeZone: string): string {
    const formattedDate = moment.tz(date, timeZone).format('YYYY-MM-DD');
    return formattedDate;
  }
   convertUtcToTimeZoneFormat(utcDateTime: string): string {
    const timeZone = this.storeTimeZone;
    return moment.utc(utcDateTime).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ');
  }
  getNextOccurrence(dayOfWeek: number) {
    const timeZone = this.storeTimeZone;
    const today = moment().tz(timeZone).startOf('week'); // Start from Sunday
    
    // Add the dayOfWeek to the start of the week
    const nextOccurrence = today.add(dayOfWeek, 'days');
    return nextOccurrence;
  }
  convertToTimeZone(time: string, timeZone: string) {
    return moment.utc(time, 'HH:mm:ss').tz(timeZone).format('HH:mm:ss');
  }
  getWeekDaysInRange(startDate: string, endDate: string, targetDayOfWeek: number): string[] {
    // Convert string dates to Date objects in UTC
    const start = new Date(startDate + 'T00:00:00Z');
    const end = new Date(endDate + 'T23:59:59Z');
  
    const targetDates: string[] = [];
  
    // Adjust start date to the first target day of the week on or after the start date
    let currentDate = new Date(start);
    currentDate.setDate(start.getDate() + ((targetDayOfWeek - start.getUTCDay() + 7) % 7));
  
    while (currentDate <= end) {
      const formattedDate = new Intl.DateTimeFormat('en-GB', {
        timeZone: 'Asia/Singapore',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      }).format(currentDate).replace(',', '');
  
      targetDates.push(formattedDate.split(' ')[0]); // Get only the date part
  
      // Move to the next week
      currentDate.setUTCDate(currentDate.getUTCDate() + 7);
    }
  
    return targetDates;
  }
}
